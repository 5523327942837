import React from 'react';

import { FEATURE_FLAGS } from '@/hooks/useFeatureFlag';
import * as Pages from '@/pages/general';

import { BehindFeatureFlag, Private } from './types';

export const studio = [
  {
    path: 'studio/v1',
    element: (
      <Private>
        <BehindFeatureFlag featureFlag={FEATURE_FLAGS.STUDIO}>
          <Pages.StudioV1 />
        </BehindFeatureFlag>
      </Private>
    ),
    children: [
      {
        path: 'image-preview/:type/:id',
        element: (
          <Private>
            <Pages.ImagePreview />
          </Private>
        ),
      },
    ],
  },
  {
    path: 'studio',
    element: (
      <Private>
        <BehindFeatureFlag featureFlag={FEATURE_FLAGS.STUDIO}>
          <Pages.Studio />
        </BehindFeatureFlag>
      </Private>
    ),
    children: [
      {
        path: 'image-preview/:type/:id',
        element: (
          <Private>
            <Pages.ImagePreview />
          </Private>
        ),
      },
    ],
  },
  {
    path: 'studio/home',
    element: (
      <Private>
        <BehindFeatureFlag featureFlag={FEATURE_FLAGS.STUDIO}>
          <Pages.Studio />
        </BehindFeatureFlag>
      </Private>
    ),
  },
];
