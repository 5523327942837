import React from 'react';
import { startCase } from 'lodash';

import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { apparelMapping } from '@/constants/index';
import { cn } from '@/helpers/utils';

import { ToneBar } from './DetailTones';

const colorFromIndex = (index) => {
  const colors = ['#68AAE3', '#E5EEFF', '#3EA67F', '#DEF2EB', '#C15F2A'];
  return colors[index % colors.length];
};

const renderToneItem = (item, index, isLast, percent) => (
  <ToneBar
    hex={item.tone?.hex}
    tone={item.tone}
    metric={item.percentage}
    isLast={isLast}
    key={index}
    variant="snapshot"
  />
);

const renderGenericItem = (item, tabName, index, isLast, hexGetter) => {
  return (
    <ToneBar
      hex={hexGetter(item, index)}
      tone={item}
      metric={item.percentage}
      isLast={isLast}
      key={index}
      tab={tabName}
      variant="snapshot"
    />
  );
};

const FeaturedImagesToolTip = ({ images }) => (
  <div className="flex flex-row items-start justify-start gap-x-0.5">
    {images.slice(0, 3).map((image, index) => (
      <div
        key={index}
        className={`aspect-[3/4] w-36 ${index === 1 ? '' : 'rounded'}`}
        style={{
          backgroundImage: `url(${image.url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></div>
    ))}
  </div>
);

const ItemContent = ({
  tabName,
  item,
  index,
  colorFromIndex,
  apparelMapping,
}) => {
  const renderColoredDot = (color) => (
    <div className="mr-2 h-3 w-3 rounded-full" style={{ background: color }} />
  );

  const renderItem = () => {
    switch (tabName) {
      case 'tone':
        return (
          <>
            {renderColoredDot(item.tone ? item.tone.hex : '#000')}
            {`${item.tone ? startCase(item.tone.name) + ' ' + item.tone.code : 'N/A'}`}
          </>
        );
      case 'pattern':
        return (
          <>
            {renderColoredDot(colorFromIndex(index))}
            {startCase(item.ssiPattern)}
          </>
        );
      case 'color':
        return (
          <>
            {renderColoredDot(item.ssiColor)}
            {startCase(item.ssiColor)}
          </>
        );
      case 'apparel':
        return (
          <>
            {renderColoredDot(colorFromIndex(index))}
            {startCase(apparelMapping[item.apparel])}
          </>
        );
      case 'attribute':
        return (
          <>
            {renderColoredDot(colorFromIndex(index))}
            {`${startCase(item.attributeKeyA || item.attributeKeyB)} - ${startCase(item.attributeValueA || item.attributeValueB)}`}
          </>
        );
      case 'fabric':
        return (
          <>
            {renderColoredDot(colorFromIndex(index))}
            {startCase(item.ssiFabric)}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-row items-center justify-start gap-x-2">
      {renderItem()}
    </div>
  );
};

const TableRowItem = ({
  item,
  index,
  tabName,
  colorFromIndex,
  apparelMapping,
}) => {
  const hasImages = item.featuredImages && item.featuredImages.length > 0;

  const renderContent = (label) => (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <div className="w-full">{label}</div>
        </TooltipTrigger>
        {hasImages && (
          <TooltipContent sideOffset={4} className="p-2">
            <FeaturedImagesToolTip images={item.featuredImages} />
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );

  return (
    <TableRow
      key={index}
      className="data-[state=selected]:bg-muted group border border-transparent transition-colors hover:border-black/5 hover:bg-[#FAF8F8]"
    >
      <TableCell className="w-3/4 px-1 py-0">
        {renderContent(
          <div
            className={`py-1.5 text-sm font-medium text-black ${hasImages ? 'cursor-pointer' : 'cursor-auto'}`}
            onClick={() => {}}
          >
            <ItemContent
              tabName={tabName}
              item={item}
              index={index}
              colorFromIndex={colorFromIndex}
              apparelMapping={apparelMapping}
            />
          </div>,
        )}
      </TableCell>
      <TableCell className="w-1/4 px-2 py-0 text-right font-medium">
        {item.percentage.toFixed(0)}%
      </TableCell>
    </TableRow>
  );
};

export const DetailBreakDown = ({ tab }) => {
  const { data, name } = tab;
  const tabName = name.toLowerCase();

  if (!data || data.length === 0) {
    return null;
  }

  const displayData = [...data].slice(0, 5);

  const renderItems = () => {
    switch (tabName) {
      case 'tone':
        return displayData.map((item, index) =>
          renderToneItem(item, index, index === displayData.length - 1),
        );
      case 'color':
        return displayData.map((item, index) =>
          renderGenericItem(
            item,
            tabName,
            index,
            index === displayData.length - 1,
            (item) => item.ssiColor,
          ),
        );
      default:
        return displayData.map((item, index) =>
          renderGenericItem(
            item,
            tabName,
            index,
            index === displayData.length - 1,
            (_, i) => colorFromIndex(i),
          ),
        );
    }
  };

  return (
    <div className="flex w-full flex-col gap-y-8">
      <div className={cn('flex w-full items-start')}>
        <div className="flex w-full flex-col">
          <div className="mb-2 flex flex-row items-center justify-between px-2 py-1.5">
            <h3 className="text-sm font-semibold leading-4 text-black">
              {tabName !== 'tone' ? name : 'Color ' + name} Distribution
            </h3>
            <h3 className="text-sm font-semibold leading-4 text-black">%</h3>
          </div>
          <div className="flex w-full flex-col items-start justify-center">
            <div className="flex w-full flex-row items-start justify-center">
              {renderItems()}
            </div>
            <Table className="w-full">
              <TableBody>
                {displayData.map((item, index) => (
                  <TableRowItem
                    key={index}
                    item={item}
                    index={index}
                    tabName={tabName}
                    colorFromIndex={colorFromIndex}
                    apparelMapping={apparelMapping}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};
