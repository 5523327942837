import { useEffect, useState } from 'react';
import { Wand } from 'lucide-react';

import { STUDIO_DISPLAY } from '@/constants/studio';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';

import { SidebarButton } from './SidebarMenu';

export const GenerateButton = ({
  variant = 'default',
  active,
  text,
  icon: Icon,
  tool,
}) => {
  const [disabled, setDisabled] = useState(true);

  const {
    createItem,
    setDisplayMode,
    positivePrompt,
    selectedArea,
    referenceImage,
    referenceSketch,
    referencePattern,
    referenceOtherImage,
    handleCreateNewStudioTask,
    selectedTone,
    studioTask,
    selectedBrushPathArray,
    setSelectedCompareImage,
    referenceGraphic,
  } = useStudioContext();

  useEffect(() => {
    if (
      studioTask?.status === 'PENDING' ||
      studioTask?.status === 'PENDING_GENERATION' ||
      studioTask?.status === 'READY_FOR_GENERATION'
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [studioTask?.status]);

  useEffect(() => {
    const isDisabled = () => {
      switch (createItem.type) {
        case 'GENERATE_APPAREL':
          return !positivePrompt;
        case 'GENERATE_PATTERN':
          return !positivePrompt;
        case 'REALIZE_SKETCH':
          return !referenceSketch;
        case 'IMAGE_BACKGROUND_REMOVER':
          return !referenceImage;
        case 'UPSCALE_IMAGE':
          return !referenceImage;
        case 'ENHANCE_IMAGE':
          return !referenceImage;
        case 'CREATE_VARIANTS':
          return !referenceImage;
        case 'PATTERN_TILE':
          return !referencePattern;
        case 'ZOOM_IN_IMAGE':
          return (
            !referenceImage ||
            !selectedArea ||
            selectedArea?.x === 0 ||
            selectedArea?.y === 0
          );
        case 'IMAGE_CHANGE_BACKGROUND':
          return !referenceOtherImage || !referenceImage;
        case 'COLOR_TRANSFER':
          return !(referenceImage && selectedTone);
        case 'INPAINTING':
          return (
            !referenceImage ||
            !selectedBrushPathArray?.length ||
            !positivePrompt
          );
        case 'GENERATE_GRAPHIC':
          return !positivePrompt;
        case 'GENERATE_FLAT_SKETCH':
          return !referenceImage;
        case 'CREATE_PATTERN_VARIANTS':
          return !referencePattern;
        default:
          return true;
      }
    };

    setDisabled(isDisabled());
  }, [
    createItem.type,
    positivePrompt,
    referenceImage,
    referenceSketch,
    selectedArea,
    referencePattern,
    referenceOtherImage,
    selectedTone,
    selectedBrushPathArray,
    referenceGraphic,
  ]);

  const handleGenerate = () => {
    handleCreateNewStudioTask(createItem.type);
    setDisplayMode(STUDIO_DISPLAY.GENERATING);
    setSelectedCompareImage('slider');
  };

  const handleGenerateTool = (tool) => {
    setDisplayMode(STUDIO_DISPLAY.GENERATING);
    setSelectedCompareImage('slider');
    handleCreateNewStudioTask(tool);
  };

  if (variant === 'tool') {
    return (
      <SidebarButton
        variant="tool"
        text={text}
        icon={Icon}
        className={cn(
          'flex cursor-pointer items-center justify-between gap-2 rounded border border-secondary/10 bg-secondary/5 px-2.5 py-1.5 text-center text-sm font-bold text-black hover:border-primary hover:bg-white hover:text-primary transition duration-300 ease-in-out shadow-sm h-full',
          active && 'border-primary bg-white text-primary',
        )}
        disabled={disabled}
        onClick={() =>
          setTimeout(() => {
            handleGenerateTool(tool);
          }, 100)
        }
      />
    );
  }

  if (variant === 'apply') {
    return (
      <SidebarButton
        text="Apply"
        disabled={disabled}
        onClick={() => !disabled && handleGenerate()}
      />
    );
  }

  return (
    <SidebarButton
      variant="generate"
      text="Generate"
      disabled={disabled || createItem.isUsingApplyButton}
      icon={Wand}
      onClick={() => !disabled && handleGenerate()}
    />
  );
};
