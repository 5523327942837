import { useEffect, useState } from 'react';

export const useMediaQuery = ({ size = 'md' }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const sizes = {
    sm: 480,
    md: 768,
    lg: 1024,
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= sizes[size];
};
