import { useMemo } from 'react';
import dayjs from 'dayjs';

import { hexToHsl } from '@/helpers/hexToHsl';
import { cn } from '@/helpers/utils';

import { monthToSeason } from '../../index';

export function Root({ children }) {
  return <div className="flex w-full items-center">{children}</div>;
}

export function ChartReferenceLine({
  children,
  className,
  lineClassName,
  color,
  label,
}) {
  return (
    <div
      className={cn(
        'absolute top-0 h-44 text-xs font-semibold flex w-32 select-none pointer-events-none ',
        className,
      )}
      style={{ color }}
    >
      <div
        className={cn('mt-0 h-44 w-[1px]', lineClassName)}
        style={{
          backgroundImage: `repeating-linear-gradient(to right, ${color} 0%, ${color} 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to right, ${color} 0%, ${color} 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, ${color} 0%, ${color} 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, ${color} 0%, ${color} 50%, transparent 50%, transparent 100%)`,
          backgroundPosition: 'left top, left bottom, left top, right top',
          backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
          backgroundSize: '10px 1px, 10px 0px, 1px 10px, 1px 0px',
        }}
      />
      <div
        className="absolute top-0 -mt-4 h-2 w-2 -translate-x-1/2 rounded-full"
        style={{ backgroundColor: color }}
      />
      <span className="ml-1.5 -mt-5">{label}</span>
    </div>
  );
}

const Tile = ({ color, season, months, key, className }) => {
  const hslColor = useMemo(() => {
    const [h, s, l] = hexToHsl(color, false, false, false);
    return `hsl(${h}, ${s}%, ${l * 0.8}%)`;
  }, [color]);

  const isNewSeason = useMemo(() => {
    const previousMonth = dayjs(months[0].date)
      .subtract(1, 'month')
      .format('MMM');

    return monthToSeason[previousMonth].season !== season;
  }, [months, season]);

  return (
    <div style={{ flexBasis: `${(months.length / 12) * 100}%` }}>
      <div
        className={cn('h-36 overflow-hidden', className)}
        style={{ background: `linear-gradient(0deg, ${color} 0%, #fff 100%)` }}
      />

      <div className="flex w-full select-none">
        {months
          .sort((a, b) => dayjs(a.date).isAfter(b.date))
          .map((month, index) => (
            <div
              key={index}
              className="flex h-8 flex-1 flex-col items-center justify-start"
            >
              <div className="h-1 w-[0.1rem]" style={{ background: color }} />
              <span className="text-xs text-charcoal">{month.month}</span>
            </div>
          ))}
      </div>
      {isNewSeason && (
        <ChartReferenceLine
          className={className}
          color={hslColor}
          label={season}
        />
      )}

      <div className="flex w-full">
        {months
          .sort((a, b) => dayjs(a.date).isAfter(b.date))
          .map((month, index) => {
            const previousMonth = dayjs(month.date)
              .subtract(1, 'month')
              .format('MMM');

            const isNewYear = previousMonth === 'Dec';

            if (!isNewYear) {
              return <div className="w-full" key={index}></div>;
            }

            return (
              <div key={index}>
                <ChartReferenceLine
                  className={className + ' h-40 -top-6'}
                  lineClassName={'h-40'}
                  color={'rgba(0,0,0,.7)'}
                  label={dayjs(month.date).format('YYYY')}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

const Chart = ({ color, season, months, key, className }) => {
  const hslColor = useMemo(() => {
    const [h, s, l] = hexToHsl(color, false, false, false);
    return `hsl(${h}, ${s}%, ${l * 0.8}%)`;
  }, [color]);

  const isNewSeason = useMemo(() => {
    const previousMonth = dayjs(months[0].date)
      .subtract(1, 'month')
      .format('MMM');

    return monthToSeason[previousMonth].season !== season;
  }, [months, season]);

  return (
    <div
      style={{
        flexBasis: `${(months.length / 12) * 100}%`,
      }}
      key={key}
    >
      <div className={cn('h-52 overflow-hidden', className)} />

      <div className="-ml-4 flex w-full select-none">
        {months
          .sort((a, b) => dayjs(a.date).isAfter(b.date))
          .map((month, index) => {
            const isFirstWeek = dayjs(month.date).date() <= 7;

            return (
              <div
                key={index}
                className={`flex h-8 flex-1 flex-col items-center justify-start ${isFirstWeek ? 'block' : 'hidden'}`}
              >
                <div
                  style={{ background: '#000', width: '1px', height: '4px' }}
                />
                {isFirstWeek && <span className="text-xs">{month.month}</span>}
              </div>
            );
          })}
      </div>

      {isNewSeason && (
        <ChartReferenceLine className="mt-10" color={hslColor} label={season} />
      )}

      <div className="flex w-full">
        {months
          .sort((a, b) => dayjs(a.date).isAfter(b.date))
          .map((month, index) => {
            const previousMonth = dayjs(month.date)
              .subtract(1, 'month')
              .format('MMM');

            const isNewYear = previousMonth === 'Dec';

            if (!isNewYear) {
              return <div className="w-full" key={index}></div>;
            }

            return <div key={index}></div>;
          })}
      </div>
    </div>
  );
};

export default {
  Root,
  Tile,
  Chart,
};
