import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import { STUDIO_TOOLS_CONFIG } from '@/constants/studio';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';

import { GenerateButton } from './components/GenerateButton';
import { SidebarButton } from './components/SidebarMenu';

const StudioToolsGrid = ({ isPattern }) => {
  const {
    selectedTool,
    setSelectedTool,
    updateCreateItemDetails,
    setGenerateSize,
  } = useStudioContext();

  const apparelTools = [
    'CREATE_VARIANTS',
    'UPSCALE_IMAGE',
    'ENHANCE_IMAGE',
    'ZOOM_IN_IMAGE',
    'IMAGE_BACKGROUND_REMOVER',
    'IMAGE_CHANGE_BACKGROUND',
    'COLOR_TRANSFER',
    'INPAINTING',
    'GENERATE_FLAT_SKETCH',
  ];

  const patternTools = [
    'CREATE_PATTERN_VARIANTS',
    'PATTERN_TILE',
    'UPSCALE_IMAGE',
    'ENHANCE_IMAGE',
    'ZOOM_IN_IMAGE',
  ];

  useEffect(() => {
    if (isPattern) {
      setGenerateSize('SQUARE');
    }
  }, [isPattern]);

  const toolsToRender = (isPattern ? patternTools : apparelTools)
    .filter((tool) =>
      import.meta.env.VITE_APP_ENV === 'development'
        ? true
        : tool !== 'INPAINTING',
    )
    .map(
      (tool, index) =>
        STUDIO_TOOLS_CONFIG[tool].canGenerate && (
          <div
            className="h-full"
            key={index}
            onClick={() => {
              setSelectedTool(tool);
              updateCreateItemDetails(tool);
            }}
          >
            <GenerateButton
              variant="tool"
              text={STUDIO_TOOLS_CONFIG[tool].buttonText}
              icon={STUDIO_TOOLS_CONFIG[tool].icon}
              tool={tool}
            />
          </div>
        ),
    );

  const sidebarToolsToRender = (isPattern ? patternTools : apparelTools).map(
    (tool, index) =>
      !STUDIO_TOOLS_CONFIG[tool].canGenerate && (
        <SidebarButton
          key={index}
          text={STUDIO_TOOLS_CONFIG[tool].buttonText}
          icon={STUDIO_TOOLS_CONFIG[tool].icon}
          variant="tool"
          active={selectedTool === tool}
          className="shadow-sm"
          onClick={() => {
            setSelectedTool(tool);
            updateCreateItemDetails(tool);
          }}
        />
      ),
  );

  return (
    <div className="grid min-w-full grid-cols-1 gap-x-4 gap-y-6 lg:grid-cols-2">
      {toolsToRender}
      {sidebarToolsToRender}
    </div>
  );
};

export const StudioTools = () => {
  const {
    openTools,
    setOpenTools,
    studioTaskData,
    studioTaskLoading,
    refSoloItemType,
    createItem,
    processStudioTaskLoading,
  } = useStudioContext();
  const [isProcessing, setIsProcessing] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('home')) {
      setOpenTools(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    setIsProcessing(
      studioTaskData?.studioTask?.status === 'PENDING_GENERATION' ||
        studioTaskLoading ||
        processStudioTaskLoading,
    );
  }, [
    studioTaskData?.studioTask?.status,
    studioTaskLoading,
    processStudioTaskLoading,
  ]);

  return (
    <div
      className={cn(
        'flex h-full w-full items-start justify-center md:justify-start',
        !openTools && 'hidden',
      )}
    >
      <div className="flex h-full w-full max-w-[36rem] flex-col items-start gap-6 p-6">
        <div className="flex w-full flex-col gap-4 pt-2">
          <h2 className="text-2xl font-bold leading-6 text-night" inert="">
            {[
              'GENERATE_PATTERN',
              'PATTERN_TILE',
              'CREATE_PATTERN_VARIANTS',
            ].includes(studioTaskData?.studioTask?.generateTaskType) ||
            refSoloItemType === 'PATTERN' ||
            createItem.type === 'GENERATE_PATTERN'
              ? 'Pattern Tools'
              : 'Design Tools'}
          </h2>
        </div>
        <StudioToolsGrid
          isPattern={
            [
              'GENERATE_PATTERN',
              'PATTERN_TILE',
              'CREATE_PATTERN_VARIANTS',
            ].includes(studioTaskData?.studioTask?.generateTaskType) ||
            refSoloItemType === 'PATTERN' ||
            createItem.type === 'GENERATE_PATTERN'
          }
        />
        <div className="flex min-h-0 w-full flex-col gap-4">
          {(studioTaskData?.studioTask?.payload?.positivePrompt ||
            isProcessing) && (
            <div className="flex w-full flex-col gap-1">
              <h3 className="text-lg font-bold leading-8 text-night">Prompt</h3>
              <p
                className={cn(
                  'text-sm text-night min-h-6 rounded',
                  isProcessing && 'ghost w-full min-w-32 h-12',
                )}
              >
                {studioTaskData?.studioTask?.payload.positivePrompt}
              </p>
            </div>
          )}
          <div className="flex w-full flex-col gap-2">
            <p className="text-xs font-bold text-secondary">
              {dayjs(studioTaskData?.studioTask?.createdAt).format(
                'D MMMM YYYY',
              )}
            </p>
          </div>
          {(studioTaskData?.studioTask?.payload?.orientation ||
            isProcessing) && (
            <div className="flex w-full flex-col gap-1">
              <h3 className="text-lg font-bold leading-8 text-night">Size</h3>
              <p
                className={cn(
                  'text-sm font-bold text-secondary h-6 rounded',
                  isProcessing && 'ghost w-1/2',
                )}
              >
                {studioTaskData?.studioTask?.payload.orientation}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
