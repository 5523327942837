import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { usePostHog } from 'posthog-js/react';

import { LOGIN_USER } from '@/api/authentication/mutations.js';
import { TFashion } from '@/assets/svg';
import { NotificationBox } from '@/components/NotificationBox';
import { errorMessages, loginMessages } from '@/constants';

import { loginFormOptions } from './loginFormOptions';

const Navbar = () => (
  <div className="flex min-h-[90px] w-full border-b border-[#A1A1A1] bg-white px-[60px]">
    <div className="flex w-[212.8px] items-center">
      <TFashion />
    </div>
  </div>
);

const Links = () => (
  <p className="font-regular text-[16px] leading-[28px] text-[#000000]">
    Don&apos;t have an account? {''}
    <NavLink
      className="border-b-2 border-primary font-semibold text-primary"
      to="/sign-up"
    >
      Sign Up
    </NavLink>
  </p>
);

export const Login = () => {
  const urlParam = new URLSearchParams(window.location.search);
  const [emailConfirmed, setEmailConfirmed] = useState(
    !!urlParam.get('verifyEmail'),
  );

  const navigate = useNavigate();
  const [loginUser, { data, loading, error }] = useMutation(LOGIN_USER, {
    onCompleted: () => {
      const { accessToken, refreshToken, user } = data.loginUser;
      const isEmailConfirmed = user?.isEmailConfirmed;
      if (isEmailConfirmed === true) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('user', JSON.stringify(user));
        navigate('/overview', { replace: true });
      } else {
        setEmailConfirmed(true);
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(loginFormOptions);

  const posthog = usePostHog();

  const onSubmit = async (data) => {
    const credentials = {
      email: data.email,
      password: data.password,
      rememberMe: true,
    };

    try {
      const response = await loginUser({ variables: { input: credentials } });

      posthog?.capture('my event', { property: 'value' });

      posthog?.capture('login_successful', {
        login_method: 'email',
        user_email: credentials.email,
        timestamp: new Date().toISOString(),
      });

      const user = response.data.loginUser.user;
      posthog.identify(user.id, {
        id: user?.id,
        email: user.email,
        name: user?.fullName || user.email,
        organization: user?.organization?.name,
      });

      datadogRum.setUser({
        id: user.id,
        email: user.email,
        name: user.fullName || user.email,
        organization: user.organization?.name,
      });
    } catch (error) {
      posthog?.capture('login_failed', {
        login_method: 'email',
        error_message: error.message,
        timestamp: new Date().toISOString(),
      });
    }
  };

  const [formRef] = useAutoAnimate();

  return (
    <div className="flex h-full w-full flex-col">
      <Navbar />
      <div className="flex w-full flex-1">
        <div className="flex h-auto w-[100%] flex-col items-center justify-center lg:w-[45%]">
          <div className="flex flex-col items-center">
            <h3 className="mb-[16px] text-[48px] font-semibold leading-[55px]">
              Welcome Back
            </h3>
            <p className="font-regular mb-[16px] text-[14px] leading-[173%] text-[#626775]">
              {loginMessages[Math.floor(Math.random() * loginMessages.length)]}
            </p>
          </div>
          {emailConfirmed && (
            <div className="mb-4">
              <NotificationBox
                color="primary"
                title="Email Confirmation"
                content="Please confirm your email to continue using the platform."
              />
            </div>
          )}
          <form
            className="mb-[22px] flex h-auto w-[404px] flex-col gap-6"
            onSubmit={handleSubmit(onSubmit)}
            ref={formRef}
          >
            {error && (
              <NotificationBox
                color="red"
                title={
                  error.message === errorMessages.invalidCredentials
                    ? 'Wrong email or password'
                    : 'Something went wrong'
                }
                content={
                  error.message === errorMessages.invalidCredentials
                    ? 'Please check your email and password and try again.'
                    : error.message
                }
              />
            )}
            {(errors.email?.message || errors.password?.message) && (
              <NotificationBox
                color="red"
                title="Missing fields"
                content={[
                  errors.email?.message || null,
                  errors.password?.message || null,
                ]}
              />
            )}
            <div className="flex flex-col gap-[14px]">
              <div className="flex w-full flex-col gap-1">
                <p className="h-[28px] px-[16px] pb-1 text-base font-semibold ">
                  E-mail
                </p>
                <input
                  className="h-[47px] border border-charcoal px-4 outline-0"
                  type="email"
                  placeholder="Email address"
                  {...register('email', { required: true })}
                />
              </div>

              <div className="flex w-full flex-col gap-[4px]">
                <p className="h-[28px] px-[16px] pb-[4px] text-base font-semibold">
                  Password
                </p>
                <input
                  className="h-[47px] border border-charcoal px-4 outline-0"
                  type="password"
                  autoComplete="on"
                  placeholder="Password"
                  {...register('password', { required: true })}
                />
              </div>
            </div>
            <div className="mt-[12px] flex h-[21px] w-full items-center justify-between">
              <div className="flex items-center justify-center">
                <input
                  className="form-check-input float-left h-[14px] w-[14px] cursor-pointer appearance-none rounded-none border border-charcoal bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-charcoal checked:bg-primary focus:outline-none"
                  type="checkbox"
                  value=""
                  id="remember"
                />
                <label
                  htmlFor="remember"
                  className="ml-[8px] flex cursor-pointer justify-center pt-0.5 text-[12px]"
                >
                  Remember Me
                </label>
              </div>
              <NavLink
                to="/forgot-password"
                className="float-right text-[12px] font-semibold underline "
              >
                Forgot your password?
              </NavLink>
            </div>

            <button className="btn-primary-without-hover h-[46px]">
              {loading ? 'Submitting...' : 'Log In'}
            </button>
          </form>
          <Links />
        </div>
        <div className="hidden h-auto w-[55%] bg-[url('/src/assets/image/login.png')] bg-cover bg-no-repeat lg:block" />
      </div>
    </div>
  );
};
