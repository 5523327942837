'use client';

import * as React from 'react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

import { cn } from '@/helpers/utils.js';

const ScrollArea = React.forwardRef(
  ({ className, children, ...props }, ref) => {
    const [showLeftGradient, setShowLeftGradient] = React.useState(false);
    const [showRightGradient, setShowRightGradient] = React.useState(false);
    const viewportRef = React.useRef(null);

    const handleScroll = React.useCallback(() => {
      if (viewportRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = viewportRef.current;

        setShowLeftGradient(scrollLeft > 0);

        setShowRightGradient(scrollLeft < scrollWidth - 8 - clientWidth);
      }
    }, []);

    React.useEffect(() => {
      const viewport = viewportRef.current;
      if (viewport) {
        viewport.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => viewport.removeEventListener('scroll', handleScroll);
      }
    }, [handleScroll]);

    return (
      <ScrollAreaPrimitive.Root
        ref={ref}
        className={cn('relative overflow-hidden', className)}
        {...props}
      >
        <ScrollAreaPrimitive.Viewport
          ref={viewportRef}
          className="h-full w-full rounded-[inherit]"
          onScroll={handleScroll}
        >
          {children}
        </ScrollAreaPrimitive.Viewport>
        {showLeftGradient && (
          <div className="pointer-events-none absolute inset-y-0 left-0 w-8 bg-gradient-to-r from-background to-transparent" />
        )}
        {showRightGradient && (
          <div className="pointer-events-none absolute inset-y-0 right-0 w-8 bg-gradient-to-l from-background to-transparent" />
        )}
        <ScrollBar className="opacity-0" />
        <ScrollAreaPrimitive.Corner />
      </ScrollAreaPrimitive.Root>
    );
  },
);
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef(
  ({ className, orientation = 'vertical', ...props }, ref) => (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
      ref={ref}
      orientation={orientation}
      className={cn(
        'flex touch-none select-none transition-opacity',
        orientation === 'vertical' && 'h-full w-2.5 p-[1px]',
        orientation === 'horizontal' && 'h-2.5 flex-col p-[1px]',
        className,
      )}
      {...props}
    >
      <ScrollAreaPrimitive.ScrollAreaThumb className="bg-border relative flex-1 rounded-full" />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
  ),
);
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
