import { useEffect, useMemo, useState } from 'react';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import * as Tabs from '@radix-ui/react-tabs';
import { debounce } from 'lodash';

import {
  EXPLORE_FASHION_ITEMS,
  EXPLORE_FASHION_ITEMS_WITH_TEXT_SEARCH,
} from '@/api/explore/queries';
import { FlexRow } from '@/components';
import { LikeButton } from '@/components/LikeButton';
import {
  returnFashionItemFilters,
  useExploreContext,
} from '@/contexts/ExploreContext';
import { FashionWeeksProvider } from '@/contexts/FashionWeeksContext';
import { cn, formatLabel } from '@/helpers/utils';
import { SaveToCollection } from '@/layouts/common/index';

import {
  ExploreLoadingScreen,
  LazyLoadTrigger,
  NoSuchResultsWereFound,
} from './components/utils';
import { Card, More } from './components';

function FashionItems() {
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [activeSeason, setActiveSeason] = useState(null);

  const {
    fashionWeekFilters: filters,
    activeTab,
    shouldRefetch,
    setShouldRefetch,
    setHiddenPills,
  } = useExploreContext();

  const [
    fetchFashionItems,
    { data, networkStatus, loading, fetchMore, error, refetch },
  ] = useLazyQuery(
    filters.search?.value?.value
      ? EXPLORE_FASHION_ITEMS_WITH_TEXT_SEARCH
      : EXPLORE_FASHION_ITEMS,
    {
      variables: {
        filters: returnFashionItemFilters(filters),
        search: filters.search?.value?.value,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (newData) => {
        if (newData?.exploreFashionWeekItems?.pageInfo?.endCursor === null) {
          setFullyLoaded(true);
        } else {
          setFullyLoaded(false);
        }
        if (newData?.exploreFashionWeekItems?.groupBySeasonEdges) {
          setHiddenPills(true);
          setActiveSeason(
            newData?.exploreFashionWeekItems?.groupBySeasonEdges.find(
              ({ edges }) => edges?.length > 0,
            )?.season,
          );
        }
      },
    },
  );

  useEffect(() => {
    if (activeTab === 'fashion-weeks') {
      fetchFashionItems();
    }
  }, [activeTab]);

  useEffect(() => {
    if (shouldRefetch) {
      refetch({
        filters: returnFashionItemFilters(filters),
      });
      setShouldRefetch(false);
    }
  }, [shouldRefetch]);

  const debouncedRefetch = useMemo(() => debounce(refetch, 300), [refetch]);

  useEffect(() => {
    const searchValue = filters?.search?.value?.value;
    if (searchValue) {
      debouncedRefetch({
        filters: returnFashionItemFilters(filters),
        search: searchValue,
      });
    } else {
      refetch({
        filters: returnFashionItemFilters(filters),
      });
    }
  }, [filters?.search?.value?.value]);

  const loadingMore = networkStatus === 3;

  if (loading && !loadingMore) {
    return <ExploreLoadingScreen />;
  }

  if (error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p>{error.message}</p>
      </div>
    );
  }

  const { edges, pageInfo, groupBySeasonEdges } =
    data?.exploreFashionWeekItems || {};

  const { endCursor, hasNextPage } = pageInfo || {};

  const renderLazyLoad =
    networkStatus !== NetworkStatus.fetchMore &&
    hasNextPage &&
    fullyLoaded === false;

  if (edges?.length === 0 && groupBySeasonEdges?.length === 0) {
    return (
      <div>
        <NoSuchResultsWereFound />
      </div>
    );
  }

  if (groupBySeasonEdges?.length > 0) {
    return (
      <div className="flex flex-col gap-4">
        <Tabs.Root defaultValue="tab1">
          <Tabs.List className="flex gap-2">
            {groupBySeasonEdges.map((season) => (
              <Tabs.Trigger
                key={season.season}
                value={season.season}
                onClick={() => setActiveSeason(season.season)}
                className={cn(
                  'text-sm font-medium p-2 border border-secondary/20 bg-secondary/5 rounded capitalize',
                  activeSeason === season.season &&
                    'text-primary border-primary',
                )}
              >
                {formatLabel(season.season)}
              </Tabs.Trigger>
            ))}
          </Tabs.List>
        </Tabs.Root>
        <SeasonGrids
          edges={
            groupBySeasonEdges?.find(({ season }) => season === activeSeason)
              ?.edges
          }
          loadingMore={loadingMore}
          renderLazyLoad={renderLazyLoad}
          fetchMore={fetchMore}
          endCursor={endCursor}
          activeTab={activeTab}
          activeSeason={activeSeason}
        />
      </div>
    );
  }
  return (
    <SeasonGrids
      edges={edges}
      loadingMore={loadingMore}
      renderLazyLoad={renderLazyLoad}
      fetchMore={fetchMore}
      endCursor={endCursor}
      activeTab={activeTab}
      loading={loading}
    />
  );
}

export function FashionWeekItems() {
  return (
    <FashionWeeksProvider>
      <FashionItems />
    </FashionWeeksProvider>
  );
}

const SeasonGrids = ({
  edges,
  loadingMore,
  renderLazyLoad,
  fetchMore,
  endCursor,
  activeTab,
  loading,
}) => {
  return (
    <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
      {edges?.map(({ node: exploreItem }, index) => (
        <Card.Root
          data={exploreItem}
          key={'explore_' + index}
          moderationEnabled={false}
        >
          <Card.Image />

          <Card.HoverContent>
            <FlexRow justify="between" items="center" className="-mr-12">
              <Card.Tones />

              <FlexRow className="self-end" justify="between" items="center">
                <SaveToCollection
                  id={exploreItem.id}
                  type="fashionItem"
                  variant="explore"
                />

                <LikeButton
                  item={exploreItem.id}
                  isLiked={exploreItem.isLiked}
                  type="fashionItem"
                />

                <More />
              </FlexRow>
            </FlexRow>

            <Card.Footer
              id={exploreItem.id}
              exploreItemId={exploreItem.photoItem}
              activeTab={activeTab}
            />
          </Card.HoverContent>
        </Card.Root>
      ))}

      {loadingMore &&
        Array.from({ length: 20 }).map((_, i) => (
          <div className="ghost aspect-[3/4] w-full" key={i}></div>
        ))}

      {renderLazyLoad && (
        <div className="mb-8 h-16 w-full bg-transparent">
          <LazyLoadTrigger
            fetchMore={fetchMore}
            cursorNext={endCursor}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};
