import { useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import * as Tabs from '@radix-ui/react-tabs';
import { debounce } from 'lodash';
import { Search, XIcon } from 'lucide-react';

import { FormInput } from '@/components/FormInput';
import { Input } from '@/components/Input/index';
import { SelectDropdown } from '@/components/SelectDropdown';
import { TabHeading } from '@/components/TabHeading';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { SocialMediaImages } from '@/pages/general';
import { FashionWeekItems } from '@/pages/general/explore/FashionItems';

const tabs = [
  // {
  //   label: 'All',
  //   value: 'all',
  //   Component: SocialMediaImages
  // },
  {
    label: 'Fashion Weeks',
    value: 'fashion-weeks',
    Component: FashionWeekItems,
  },
  {
    label: 'Social Media',
    value: 'social-media',
    Component: SocialMediaImages,
  },
  // {
  //   label: 'AI Designs',
  //   value: 'ai-designs',
  //   Component: SocialMediaImages
  // }
];

const unwantedFilterKeys = ['audience', 'search'];

function returnPillLabel(key, filter) {
  if (key === 'market') {
    return `${filter?.value?.label?.toLowerCase() || filter?.label?.toLowerCase()}`;
  }

  if (key === 'personPosition') {
    return `Person Position: ${filter?.value?.label?.toLowerCase()}`;
  }

  if (key === 'personCount') {
    return `Person Count: ${filter?.value?.label?.toLowerCase()}`;
  }

  if (key === 'category') {
    return filter?.label?.toLowerCase();
  }

  if (key === 'gender' || key === 'platform') {
    return (
      (key === 'gender' ? 'Gender: ' : 'Platform: ') +
      (filter?.value?.label || filter?.value?.name).toLowerCase()
    );
  }

  if (key === 'color') {
    return filter?.value?.label;
  }

  return (
    filter?.value?.label ||
    filter?.value?.name ||
    filter?.value[0]?.label
  )?.toLowerCase();
}

function ExploreSort() {
  const { isEnabled: exploreAdvanced } = useFeatureFlag(
    FEATURE_FLAGS.EXPLORE_ADVANCED,
  );

  const { socialMediaSort, handleSocialMediaSortOnChange, activeTab } =
    useExploreContext();

  if (!exploreAdvanced || activeTab === 'fashion-weeks') {
    return null;
  }

  return Object.entries(socialMediaSort).map(([sortKey, sort], i) => (
    <FormInput className="relative right-0 max-w-[8rem]" key={i}>
      <SelectDropdown
        variant="sort"
        placeholder={sort?.placeholder}
        isSearchable={false}
        options={sort?.options}
        multiselect={false}
        isClearable={true}
        value={sort?.value}
        key={sort?.key}
        className="font-normal"
        onChange={(e) => handleSocialMediaSortOnChange(e, sortKey)}
      />
    </FormInput>
  ));
}

function ExploreFilterPills() {
  const {
    includes,
    setIncludes,
    excludes,
    setExcludes,
    activeTab,
    fashionWeekFilters,
    socialMediaFilters,
    handleFashionWeekFiltersOnChange,
    handleSocialMediaFiltersOnChange,
    shouldRefetch,
    triggerRefetch,
    fashionWeekClearAll,
    socialMediaClearAll,
    hiddenPills,
  } = useExploreContext();

  useEffect(() => {
    setIncludes([]);
    setExcludes([]);
    if (activeTab === 'fashion-weeks') {
      fashionWeekClearAll();
    } else {
      socialMediaClearAll();
    }
    if (shouldRefetch) {
      triggerRefetch();
    }
  }, [activeTab]);

  useEffect(() => {
    if (shouldRefetch === true) {
      // For deep copy, cause left side filter when changed triggered here;
      const newPills = JSON.parse(
        JSON.stringify(
          Object.entries(
            activeTab === 'fashion-weeks'
              ? fashionWeekFilters
              : socialMediaFilters,
          ).filter(
            ([key, pill]) => pill.value && !unwantedFilterKeys.includes(key),
          ),
        ),
      );

      setIncludes(newPills);

      const allExclude = JSON.parse(
        JSON.stringify(
          Object.entries(socialMediaFilters).filter(
            ([key, pill]) =>
              pill.excludeValue && !unwantedFilterKeys.includes(key),
          ),
        ),
      );

      setExcludes(allExclude);
    }
  }, [shouldRefetch, activeTab, fashionWeekFilters, socialMediaFilters]);

  function handleRemovePill(key, filter, attribute) {
    const updatePills = (updatedFilters = null) => {
      setIncludes((prevPills) => {
        if (!updatedFilters) {
          return prevPills.filter(([pillKey]) => pillKey !== key);
        } else {
          return prevPills.map(([pillKey, pill]) =>
            pillKey === key
              ? [pillKey, { ...pill, value: updatedFilters }]
              : [pillKey, pill],
          );
        }
      });
      triggerRefetch();
    };

    if (activeTab === 'fashion-weeks') {
      handleFashionWeekFiltersOnChange(null, key);
      updatePills();
    } else {
      if (key === 'category') {
        handleSocialMediaFiltersOnChange(null, key, true);
        handleSocialMediaFiltersOnChange(null, 'attribute.custom', true);
        updatePills();
      } else if (key === 'attribute.custom') {
        const newFilters = filter.value.filter(
          (attr) => attr.value !== attribute.value,
        );

        if (newFilters.length === 0) {
          handleSocialMediaFiltersOnChange(null, key, true);
          updatePills();
        } else {
          handleSocialMediaFiltersOnChange(newFilters, key, true);
          updatePills(newFilters);
        }
      } else {
        if (key === 'market') {
          // İs multiple click, remove just clicked market
          const removeMarket = {
            audVal: filter.value,
            marketId: filter.marketId,
          };
          handleSocialMediaFiltersOnChange(removeMarket, key, false);
        } else {
          handleSocialMediaFiltersOnChange(null, key);
        }
        updatePills();
      }
    }
  }

  const includePills = includes.filter(([key, filter]) => {
    if (key === 'gender' || key === 'platform') return false;
    return filter.value && Array.isArray(filter.value)
      ? !filter.value.includes(null)
      : true;
  });

  const excludePills = excludes.filter(([key, filter]) => {
    if (key === 'gender' || key === 'platform') return false;
    return filter.excludeValue && Array.isArray(filter.excludeValue)
      ? !filter.excludeValue.includes(null)
      : true;
  });

  return (
    <div className="flex w-full flex-col gap-y-4">
      <div
        className={cn(
          'flex w-full overflow-y-auto items-center gap-x-4 gap-y-2 pr-4 bg-background border border-backgroundBorder p-4 rounded',
          hiddenPills && 'hidden',
        )}
      >
        <span className="text-sm font-semibold capitalize text-[#565758]">
          Includes
        </span>
        {includePills.length === 0 && (
          <div className="flex shrink-0 items-center gap-2 rounded border border-backgroundBorder bg-white p-2 text-night">
            <span className={cn('text-night text-sm font-semibold capitalize')}>
              {activeTab === 'fashion-weeks'
                ? 'All Fashion Weeks'
                : 'All Markets'}
            </span>
          </div>
        )}
        {includePills.map(([key, filter]) =>
          key === 'attribute.custom' ? (
            filter?.value?.map((attribute) => (
              <div
                className="flex shrink-0 items-center gap-2 rounded border border-backgroundBorder bg-white p-2 text-night"
                key={attribute.name}
              >
                <span
                  className={cn('text-night text-sm font-semibold capitalize')}
                >
                  {attribute.name}: {attribute.value}
                </span>
                <button
                  onClick={() => handleRemovePill(key, filter, attribute)}
                >
                  <XIcon size={16} />
                </button>
              </div>
            ))
          ) : filter.isMulti ? (
            filter.value.map((valueItem, index) => (
              <div
                className="flex shrink-0 items-center gap-2 rounded border border-backgroundBorder bg-white p-2 text-night"
                key={`${key}-${index}`}
              >
                {key === 'color' && valueItem.hex && (
                  <div
                    className="h-4 w-4 gap-4 rounded-full"
                    style={{
                      background: valueItem.hex,
                    }}
                  />
                )}
                <span
                  className={cn('text-night text-sm font-semibold capitalize')}
                >
                  {returnPillLabel(key, valueItem) || valueItem.label}
                </span>
                {key !== 'platform' && key !== 'gender' && (
                  <button onClick={() => handleRemovePill(key, valueItem)}>
                    <XIcon size={16} />
                  </button>
                )}
              </div>
            ))
          ) : (
            <div
              className="flex shrink-0 items-center gap-2 rounded border border-backgroundBorder bg-white p-2 text-night"
              key={key}
            >
              {key === 'color' && filter?.value?.hex && (
                <div
                  className="h-4 w-4 gap-4 rounded-full"
                  style={{
                    background: filter.value.hex,
                  }}
                />
              )}
              <span
                className={cn('text-night text-sm font-semibold capitalize')}
              >
                {returnPillLabel(key, filter)}
              </span>
              {key !== 'platform' && key !== 'gender' && (
                <button onClick={() => handleRemovePill(key, filter)}>
                  <XIcon size={16} />
                </button>
              )}
            </div>
          ),
        )}
      </div>
      {import.meta.env.VITE_APP_ENV === 'development' &&
        excludePills.length > 0 && (
          <div className="flex w-full items-center gap-x-4 gap-y-2 overflow-y-auto rounded border border-backgroundBorder bg-background p-4">
            <span className="text-sm font-semibold capitalize text-[#565758]">
              Excludes
            </span>
            {excludePills.map(([key, filter]) => (
              <div className="flex flex-row gap-y-2" key={key}>
                {filter.excludeValue.map((value) => (
                  <div
                    className="flex items-center gap-2 rounded border border-backgroundBorder bg-white p-2 text-night"
                    key={value}
                  >
                    <span
                      className={cn(
                        'text-night text-sm font-semibold capitalize',
                      )}
                    >
                      {value.label}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
    </div>
  );
}

function ExploreSocialMediaSearch() {
  const { socialMediaFilters, handleSocialMediaFiltersOnChange } =
    useExploreContext();

  const search = socialMediaFilters?.search || {};
  const [inputValue, setInputValue] = useState(search.value || '');
  const [isTyping, setIsTyping] = useState(false);

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        setIsTyping(false);
        if (value.trim()) {
          handleSocialMediaFiltersOnChange(value, 'search');
        }
      }, 1000),
    [handleSocialMediaFiltersOnChange],
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsTyping(true);
    debouncedSearch(value);
  };

  const handleClearSearch = () => {
    if (isTyping) setIsTyping(false);
    setInputValue('');
    handleSocialMediaFiltersOnChange('', 'search');
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <div className="flex min-w-[20rem] items-center gap-2 rounded-md border-5 border-[#1A1A1A] bg-white px-4 py-2 outline-none">
      <Search size={24} strokeWidth={1.5} stroke="#a1a1a1" />
      <Input
        type="text"
        placeholder={search.placeholder}
        value={inputValue}
        onChange={handleInputChange}
        className="w-full text-[0.875rem] font-medium leading-6 outline-none"
      />
      {inputValue && (
        <XIcon
          size={18}
          strokeWidth={3.5}
          className="mt-0.5 cursor-pointer text-black"
          onClick={handleClearSearch}
        />
      )}
    </div>
  );
}

export function ExploreFashionWeekSearch() {
  const {
    fashionWeekFilters,
    handleFashionWeekFiltersOnChange,
    loadFashionWeekSearchOptions,
  } = useExploreContext();

  const search = fashionWeekFilters?.search;
  const [isTyping, setIsTyping] = useState(false);
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    setIsOptionsLoading(true);
    loadFashionWeekSearchOptions(inputValue, (options) => {
      callback(options);
      setIsOptionsLoading(false);
    });
  }, 500);

  const handleInputChange = (selectedOption) => {
    setIsTyping(true);
    handleFashionWeekFiltersOnChange(selectedOption, 'search');
  };

  useEffect(() => {
    return () => {
      debouncedLoadOptions.cancel();
      setIsTyping(false);
    };
  }, [debouncedLoadOptions]);

  return (
    <div className="min-w-[20rem]">
      <FormInput>
        <SelectDropdown
          placeholder={search?.placeholder}
          isSearchable={search?.isSearchable}
          options={search?.options}
          multiselect={false}
          isClearable={search?.isClearable}
          value={search?.value}
          key={'search'}
          onChange={handleInputChange}
          isAsync={true}
          isCreatable={true}
          formatCreateLabel={(inputValue) => `${inputValue}`}
          cacheOptions={false}
          loadOptions={!isTyping ? debouncedLoadOptions : undefined}
          defaultOptions
          classNames={{
            control: 'min-w-[20rem]',
            valueContainer: 'min-w-[12rem]',
          }}
          allowCreateWhileLoading={true}
          createOptionPosition={'first'}
          variant="search"
          noOptionsMessage={() => ''}
          loading={isOptionsLoading}
          icon={<Search size={20} strokeWidth={1.5} stroke="#a1a1a1" />}
          components={{
            Option: ({ selectProps, innerProps, innerRef, data, ...props }) => {
              return (
                <div
                  ref={innerRef}
                  {...innerProps}
                  className={props.getClassNames(props.type, props)}
                >
                  <Highlighter
                    highlightClassName="bg-transparent"
                    unhighlightClassName="font-semibold"
                    searchWords={[selectProps.inputValue]}
                    autoEscape={true}
                    textToHighlight={data.label}
                  />
                </div>
              );
            },
          }}
        />
      </FormInput>
    </div>
  );
}

export function ExploreTabs({ className }) {
  const { activeTab, setActiveTab, shouldRefetch } = useExploreContext();
  const contentRef = useRef(null);

  useEffect(() => {
    if (shouldRefetch && contentRef.current) {
      const scrollableContent =
        contentRef.current.querySelector('.overflow-y-auto');
      if (scrollableContent) {
        scrollableContent.scrollTo(0, 0);
      }
    }
  }, [shouldRefetch]);

  return (
    <div
      className={cn('h-full w-full overflow-y-auto relative', className)}
      ref={contentRef}
    >
      <Tabs.Root
        value={activeTab}
        onValueChange={(tab) => {
          setActiveTab(tab);
        }}
        className={'relative flex h-full flex-col overflow-y-auto'}
      >
        <div className="sticky top-0 z-[400] bg-white pt-6">
          <div className="flex items-center justify-between px-[3.75rem] pb-6">
            <Tabs.List
              aria-label="tabs"
              className={'flex w-full items-center justify-between'}
            >
              <div className={'flex gap-12'}>
                {tabs.map((tab, i) => (
                  <TabHeading label={tab.label} value={tab.value} key={i} />
                ))}
              </div>
            </Tabs.List>

            {activeTab === 'social-media' ? (
              <ExploreSocialMediaSearch />
            ) : (
              <ExploreFashionWeekSearch />
            )}
          </div>

          <div className="flex w-full items-start justify-end gap-x-6 px-[3.75rem] pb-6">
            <ExploreFilterPills />
            <ExploreSort />
          </div>
        </div>

        {tabs.map((tab, i) => (
          <Tabs.Content
            key={i}
            value={tab.value}
            className={'h-full w-full px-[3.75rem]'}
          >
            <tab.Component />
          </Tabs.Content>
        ))}
      </Tabs.Root>
    </div>
  );
}
