import { useEffect, useState } from 'react';

import { useStudioContext } from '@/contexts/StudioContext';

const minSelectedAreaRatio = {
  COLOR_TRANSFER: 0.01,
  ZOOM_IN_IMAGE: 0.33,
};

const allowedAspectRatios = [
  {
    name: 'square',
    ratio: 1,
  },
  {
    name: 'landscape',
    ratio: 4 / 3,
  },
  {
    name: 'portrait',
    ratio: 3 / 4,
  },
  {
    name: 'custom',
    ratio: 0,
  },
];

const StudioImage = ({
  image,
  onChangeSelectedArea,
  onChangeBrush,
  onClickHook,
  actionType = 'default',
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(false);

  const [drawing, setDrawing] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [stretching, setStretching] = useState(null);

  const [selectedArea, setSelectedArea] = useState({
    x: 0,
    y: 0,
    width: image?.width || 100,
    height: image?.height || 100,
  });
  const [brushCoordinates, setBrushCoordinates] = useState({});
  const [brushPaths, setBrushPaths] = useState([]);
  const [undoBrushPaths, setUndoBrushPaths] = useState([]);

  const {
    selectedAreaType,
    selectedBrushSize,
    setSelectedBrushSize,
    createItem,
  } = useStudioContext();

  useEffect(() => {
    setSelectedArea({
      x: 0,
      y: 0,
      width: image?.width || 100,
      height: image?.height || 100,
    });
    setBrushCoordinates({});
  }, [selectedAreaType, actionType]);

  useEffect(() => {
    if (selectedBrushSize === 0) {
      setBrushCoordinates({});
      setBrushPaths([]);
      setUndoBrushPaths([]);
      setTimeout(() => {
        setSelectedBrushSize(10);
      }, 250);
    }
    if (selectedBrushSize === -1) {
      if (brushPaths.length > 0) {
        const undoBrushPath = brushPaths.pop();
        setUndoBrushPaths((prev) => [...prev, undoBrushPath]);
      }
    }
    if (selectedBrushSize === -2) {
      if (undoBrushPaths.length > 0) {
        setBrushPaths((prev) => [...prev, undoBrushPaths.pop()]);
      }
    }
  }, [selectedBrushSize]);

  const ratio =
    allowedAspectRatios.find((ratio) => ratio.name === selectedAreaType)
      ?.ratio || 1;

  useEffect(() => {
    if (actionType === 'area') {
      onChangeSelectedArea({
        x: Math.floor(selectedArea.x),
        y: Math.floor(selectedArea.y),
        width: Math.floor(selectedArea.width),
        height: Math.floor(selectedArea.height),
      });
    } else if (actionType === 'brush') {
      onChangeBrush(brushPaths);
    }
  }, [selectedArea, selectedBrushSize, brushPaths]);

  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [startSelectedArea, setStartSelectedArea] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (actionType === 'area') {
      setSelectedArea({
        x: 0,
        y: 0,
        width: image?.width,
        height: image?.height,
      });
    }
  }, []);

  useEffect(() => {
    if (image.url) {
      const imageToLoad = new window.Image();
      imageToLoad.src = image.url;
      imageToLoad.onload = () => {
        setImageLoading(false);
      };

      if (!initialLoad) {
        setSelectedArea({
          x: 0,
          y: 0,
          width: image.width,
          height: image.height,
        });

        setInitialLoad(true);
      }
    }
  }, [image, initialLoad]);

  const onMouseDownSelectArea = (event) => {
    const svg = event.currentTarget;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    const ctm = svg.getScreenCTM().inverse();
    const svgPoint = point.matrixTransform(ctm);

    if (
      selectedArea.x > 0 &&
      selectedArea.y > 0 &&
      (Math.abs(svgPoint.x - selectedArea.x) < 15 ||
        Math.abs(svgPoint.x - (selectedArea.x + selectedArea.width)) < 15 ||
        Math.abs(svgPoint.y - selectedArea.y) < 15 ||
        Math.abs(svgPoint.y - (selectedArea.y + selectedArea.height)) < 15)
    ) {
      setStretching({
        corner: getCorner(svgPoint, selectedArea),
      });
    } else if (
      selectedArea.x > 0 &&
      selectedArea.y > 0 &&
      svgPoint.x > selectedArea.x &&
      svgPoint.x < selectedArea.x + selectedArea.width &&
      svgPoint.y > selectedArea.y &&
      svgPoint.y < selectedArea.y + selectedArea.height
    ) {
      setDragging(true);
      setStartPoint({
        x: Math.floor(svgPoint.x - selectedArea.x),
        y: Math.floor(svgPoint.y - selectedArea.y),
      });
    } else {
      setDrawing(true);
      setSelectedArea({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      });
      setStartPoint({
        x: Math.floor(svgPoint.x),
        y: Math.floor(svgPoint.y),
        width: 0,
        height: 0,
      });
    }
  };

  const onMouseMoveSelectArea = (event) => {
    if (!drawing && !dragging && !stretching) return;

    const svg = event.currentTarget;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    const ctm = svg.getScreenCTM().inverse();
    const svgPoint = point.matrixTransform(ctm);

    if (dragging) {
      if (
        Math.floor(svgPoint.x - startPoint.x) < 0 ||
        Math.floor(svgPoint.y - startPoint.y) < 0 ||
        Math.floor(svgPoint.x - startPoint.x) > image.width ||
        Math.floor(svgPoint.y - startPoint.y) > image.height
      ) {
        return;
      }

      setSelectedArea({
        ...selectedArea,
        x: Math.floor(svgPoint.x - startPoint.x),
        y: Math.floor(svgPoint.y - startPoint.y),
      });
    } else if (drawing) {
      const newSelectedArea = {
        x: Math.floor(Math.min(startPoint.x, svgPoint.x)),
        y: Math.floor(Math.min(startPoint.y, svgPoint.y)),
      };

      if (selectedAreaType === 'custom') {
        newSelectedArea.width = Math.floor(Math.abs(startPoint.x - svgPoint.x));
        newSelectedArea.height = Math.floor(
          Math.abs(startPoint.y - svgPoint.y),
        );
      } else {
        const width = Math.floor(Math.abs(startPoint.x - svgPoint.x));
        const height = Math.floor(width * (ratio / (ratio * ratio)));
        newSelectedArea.width = width;
        newSelectedArea.height = height;
        if (newSelectedArea.y + height > image.height) {
          newSelectedArea.y = image.height - height;
        }
      }
      setSelectedArea(newSelectedArea);
    } else if (stretching) {
      const newSelectedArea = { ...selectedArea };

      if (selectedAreaType === 'custom') {
        const { x, y } = svgPoint;
        const { x: sx, y: sy, width: sw, height: sh } = selectedArea;
        switch (stretching.corner) {
          case 'top-left':
            Object.assign(newSelectedArea, {
              x: Math.floor(x),
              y: Math.floor(y),
              width: Math.floor(sx + sw - x),
              height: Math.floor(sh + sy - y),
            });
            break;
          case 'top-right':
            Object.assign(newSelectedArea, {
              y: Math.floor(y),
              width: Math.floor(x - sx),
              height: Math.floor(sy + sh - y),
            });
            break;
          case 'bottom-left':
            Object.assign(newSelectedArea, {
              x: Math.floor(x),
              width: Math.floor(sx + sw - x),
              height: Math.floor(y - sy),
            });
            break;
          case 'bottom-right':
            newSelectedArea.width = Math.floor(x - sx);
            newSelectedArea.height = Math.floor(y - sy);
            break;
        }
      } else {
        switch (stretching.corner) {
          case 'top-left':
            // eslint-disable-next-line no-case-declarations
            const [newWidthTL, newHeightTL] = [
              Math.floor(selectedArea.x + selectedArea.width - svgPoint.x),
              Math.floor(selectedArea.y + selectedArea.height - svgPoint.y),
            ];
            if (ratio !== 0) {
              const aspectRatio = newWidthTL / newHeightTL;
              newSelectedArea.width =
                aspectRatio > ratio ? newHeightTL * ratio : newWidthTL;
              newSelectedArea.height =
                aspectRatio > ratio ? newHeightTL : newWidthTL / ratio;
            } else {
              [newSelectedArea.width, newSelectedArea.height] = [
                newWidthTL,
                newHeightTL,
              ];
            }
            [newSelectedArea.x, newSelectedArea.y] = [
              Math.floor(svgPoint.x),
              Math.floor(svgPoint.y),
            ];
            break;
          case 'top-right':
            // eslint-disable-next-line no-case-declarations
            const [newWidthTR, newHeightTR] = [
              Math.floor(svgPoint.x - selectedArea.x),
              Math.floor(selectedArea.y + selectedArea.height - svgPoint.y),
            ];
            if (ratio !== 0) {
              const aspectRatio = newWidthTR / newHeightTR;
              newSelectedArea.width =
                aspectRatio > ratio ? newHeightTR * ratio : newWidthTR;
              newSelectedArea.height =
                aspectRatio > ratio ? newHeightTR : newWidthTR / ratio;
            } else {
              [newSelectedArea.width, newSelectedArea.height] = [
                newWidthTR,
                newHeightTR,
              ];
            }
            newSelectedArea.y = Math.floor(svgPoint.y);
            break;
          case 'bottom-left':
            // eslint-disable-next-line no-case-declarations
            const [newWidthBL, newHeightBL] = [
              Math.floor(selectedArea.x + selectedArea.width - svgPoint.x),
              Math.floor(svgPoint.y - selectedArea.y),
            ];
            if (ratio !== 0) {
              const aspectRatio = newWidthBL / newHeightBL;
              newSelectedArea.width =
                aspectRatio > ratio ? newHeightBL * ratio : newWidthBL;
              newSelectedArea.height =
                aspectRatio > ratio ? newHeightBL : newWidthBL / ratio;
            } else {
              [newSelectedArea.width, newSelectedArea.height] = [
                newWidthBL,
                newHeightBL,
              ];
            }
            newSelectedArea.x = Math.floor(svgPoint.x);
            break;
          case 'bottom-right':
            // eslint-disable-next-line no-case-declarations
            const [newWidthBR, newHeightBR] = [
              Math.floor(svgPoint.x - selectedArea.x),
              Math.floor(svgPoint.y - selectedArea.y),
            ];
            if (ratio !== 0) {
              const aspectRatio = newWidthBR / newHeightBR;
              newSelectedArea.width =
                aspectRatio > ratio ? newHeightBR * ratio : newWidthBR;
              newSelectedArea.height =
                aspectRatio > ratio ? newHeightBR : newWidthBR / ratio;
            } else {
              [newSelectedArea.width, newSelectedArea.height] = [
                newWidthBR,
                newHeightBR,
              ];
            }
            break;
          default:
            break;
        }
      }
      setStartSelectedArea(newSelectedArea);
    }
  };

  const onMouseDownBrush = (event) => {
    const svg = event.currentTarget;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    const ctm = svg.getScreenCTM().inverse();
    const svgPoint = point.matrixTransform(ctm);

    setStartPoint({
      x: svgPoint.x,
      y: svgPoint.y,
    });

    setDrawing(true);
  };

  const onMouseMoveBrush = (event) => {
    if (!drawing) return;

    const svg = event.currentTarget;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    const ctm = svg.getScreenCTM().inverse();
    const svgPoint = point.matrixTransform(ctm);

    const newPoint = {
      x: svgPoint.x,
      y: svgPoint.y,
    };
    if (selectedBrushSize !== 0) {
      setBrushCoordinates((prev) => {
        const newPath = `M ${startPoint.x.toFixed(0)} ${startPoint.y.toFixed(0)} L ${newPoint.x.toFixed(0)} ${newPoint.y.toFixed(0)}`;
        const paths = Object.keys(prev);
        return {
          [paths.join(' ') + ' ' + newPath]: true,
        };
      });
    }

    setStartPoint(newPoint);
  };

  const onMouseUp = () => {
    if (actionType === 'default' && onClickHook) {
      onClickHook();
      return;
    }

    if (actionType === 'brush') {
      onClickHook();
      setBrushPaths((prev) => {
        return [
          ...prev,
          {
            path: Object.keys(brushCoordinates).join(' '),
            size: selectedBrushSize,
          },
        ];
      });
      setBrushCoordinates({});
      setDrawing(false);
      return;
    }

    if (drawing) {
      if (
        selectedArea.width <
          minSelectedAreaRatio[createItem.type] * image.width ||
        selectedArea.height <
          minSelectedAreaRatio[createItem.type] * image.height
      ) {
        setSelectedArea({
          x: 0,
          y: 0,
          width: image.width,
          height: image.height,
        });
        setStartSelectedArea({
          x: 0,
          y: 0,
          width: image.width,
          height: image.height,
        });
        if (onClickHook) {
          onClickHook();
        }
      }
      setDrawing(false);
    }

    if (dragging) {
      setDragging(false);
    }

    if (stretching) {
      setStretching(null);
    }
    if (startSelectedArea) {
      setSelectedArea(startSelectedArea);
      setStartSelectedArea(null);
    }
  };

  const MouseEventsMap = {
    area: {
      onMouseDown: onMouseDownSelectArea,
      onMouseMove: onMouseMoveSelectArea,
      onMouseUp,
    },
    brush: {
      onMouseDown: onMouseDownBrush,
      onMouseMove: onMouseMoveBrush,
      onMouseUp,
    },
    default: {
      onMouseUp,
    },
  };

  const getCorner = (point, area) => {
    const corners = {
      'top-left': [area.x, area.y],
      'top-right': [area.x + area.width, area.y],
      'bottom-left': [area.x, area.y + area.height],
      'bottom-right': [area.x + area.width, area.y + area.height],
    };

    for (const cornerEl in corners) {
      if (
        Math.abs(point.x - corners[cornerEl][0]) < 15 &&
        Math.abs(point.y - corners[cornerEl][1]) < 15
      ) {
        return cornerEl;
      }
    }

    return null;
  };

  return imageLoading ? (
    <div></div>
  ) : (
    <div>
      {/*   <Drawing image={image} /> */}
      <svg
        className="z-40 h-full w-full max-w-[48rem] shadow-xl"
        viewBox={`0 0 ${image.width} ${image.height}`}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio={'xMidYMid meet'}
        {...MouseEventsMap[actionType]}
      >
        <image
          href={image?.url}
          width={image.width}
          height={image.height}
          className="pointer-events-none"
        />
        <defs>
          <mask id="Mask">
            <rect
              fill="#FAF8F8"
              height="100%"
              rx="0"
              ry="0"
              width="100%"
              x="0"
              y="0"
            ></rect>
            <rect
              height={selectedArea.height}
              id="hole"
              rx="0"
              ry="0"
              width={selectedArea.width}
              x={selectedArea.x}
              y={selectedArea.y}
              style={{
                cursor:
                  selectedArea.height > 0 &&
                  selectedArea.height !== image.height
                    ? 'grab'
                    : 'inherit',
              }}
            ></rect>
          </mask>
        </defs>
        <rect
          height="100%"
          mask="url(#Mask)"
          width="100%"
          x="0"
          y="0"
          onMouseUp={() => {
            if (!drawing) {
              setSelectedArea({
                x: 0,
                y: 0,
                width: image.width,
                height: image.height,
              });
            } else if (selectedArea.width === 0 || selectedArea.height === 0) {
              setSelectedArea({
                x: 0,
                y: 0,
                width: image.width,
                height: image.height,
              });
            }
          }}
          style={{
            fill: 'rgba(0, 0, 0, 0.5)',
          }}
        ></rect>
        <use fill="rgba(0,0,0,0)" href="#hole" stroke="#fff" strokeWidth={2}>
          <rect
            height={selectedArea.height}
            id="hole"
            rx="0"
            ry="0"
            width={selectedArea.width}
            x={selectedArea.x}
            y={selectedArea.y}
          ></rect>
        </use>

        {drawing === false && (
          <>
            <g
              transform={`translate(${selectedArea.x - 12}, ${
                selectedArea.y - 12
              }), rotate(0)`}
              className="cursor-nw-resize"
            >
              <rect fill="rgba(0,0,0,0)" height={30} width={30} x={0} y={0} />
              <path
                d="M24 0H36V12H24C17.37255 12 12 17.37255 12 24V36H0V24C0 10.7452 10.7452 0 30 0Z"
                fill="#FAF8F8"
              />
            </g>

            <g
              transform={`translate(${
                selectedArea.x + selectedArea.width + 12
              }, ${selectedArea.y - 12}), rotate(90)`}
              className="cursor-ne-resize"
            >
              <rect fill="rgba(0,0,0,0)" height={30} width={30} x={0} y={0} />
              <path
                d="M24 0H36V12H24C17.37255 12 12 17.37255 12 24V36H0V24C0 10.7452 10.7452 0 24 0Z"
                fill="#FAF8F8"
              />
            </g>
            <g
              transform={`translate(${selectedArea.x - 12}, ${
                selectedArea.height + selectedArea.y + 12
              }), rotate(270)`}
              className="cursor-sw-resize"
            >
              <rect fill="rgba(0,0,0,0)" height={30} width={30} x={0} y={0} />
              <path
                d="M24 0H36V12H24C17.37255 12 12 17.37255 12 24V36H0V24C0 10.7452 10.7452 0 24 0Z"
                fill="#FAF8F8"
              />
            </g>
            <g
              transform={`translate(${
                selectedArea.width + selectedArea.x + 12
              }, ${selectedArea.height + selectedArea.y + 12}), rotate(180)`}
              className="cursor-se-resize"
            >
              <rect fill="rgba(0,0,0,0)" height={30} width={30} x={0} y={0} />
              <path
                d="M24 0H36V12H24C17.37255 12 12 17.37255 12 24V36H0V24C0 10.7452 10.7452 0 24 0Z"
                fill="#FAF8F8"
              />
            </g>
          </>
        )}

        {actionType === 'brush' && (
          <>
            {brushPaths.map(({ path, size }, index) => (
              <path
                key={index}
                d={path}
                stroke="rgba(0,0,0,0.8)"
                strokeWidth={size}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0.1"
              />
            ))}
            <path
              d={Object.keys(brushCoordinates).join(' ')}
              stroke="rgba(0,0,0,0.8)"
              strokeWidth={selectedBrushSize}
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              fillOpacity="0.1"
            />
          </>
        )}

        <rect
          fill="rgba(0,0,0,0)"
          height="20"
          stroke="none"
          className="cursor-ns-resize"
          width={selectedArea.width}
          x={selectedArea.x}
          y={selectedArea.y - 10}
        ></rect>

        <rect
          fill="rgba(0,0,0,0)"
          height={selectedArea.height}
          stroke="none"
          width="20"
          className="cursor-ew-resize"
          x={selectedArea.x - 10}
          y={selectedArea.y - 10}
        ></rect>

        <rect
          fill="rgba(0,0,0,0)"
          height="20"
          stroke="none"
          width={selectedArea.width}
          className="cursor-ns-resize"
          x={selectedArea.x}
          y={selectedArea.y + selectedArea.height - 5}
        ></rect>

        <rect
          fill="rgba(0,0,0,0)"
          height={selectedArea.height}
          stroke="none"
          width="20"
          className="cursor-ew-resize"
          x={selectedArea.x + selectedArea.width}
          y={selectedArea.y}
        ></rect>
      </svg>
    </div>
  );
};

export default StudioImage;
