import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  FASHION_WEEK_ITEM,
  GET_STUDIO_ITEM,
  SOCIAL_MEDIA_ITEM,
  UPLOAD_ITEM_IMAGE,
} from '@/api/studio/queries';
import { STUDIO_DISPLAY } from '@/constants/studio';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn, downloadFile } from '@/helpers/utils';
import { useMediaQuery } from '@/hooks/useMediaQuery';

import { AsideBar, Collections } from './components/AsidebarMenu';
import { StudioExploreSideBar } from './components/StudioExploreSideBar';
import { StudioHistorySidebar } from './components/StudioHistorySidebar';
import { ImageDisplay } from './StudioGeneration';
import { StudioHome } from './StudioHome';
import { StudioLeftSidebar } from './StudioLeftSidebar';
import { StudioTools } from './StudioTools';

export const StudioLayout = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const {
    activeModal,
    setActiveModal,
    updateCreateItemDetails,
    setGeneratedImages,
    studioTaskData,
    orientation,
    openTools,
    setSelectedGeneratedImage,
    setReferencePattern,
    setReferenceImage,
    setDisplayMode,
    setReferenceSketch,
    setRefSoloItemType,
    studioTask,
    setStudioTask,
    similarImagesData,
    similarImagesLoading,
    similarImagesError,
    setReferenceGraphic,
  } = useStudioContext();

  const isHome = location.pathname.includes('home');
  const searchParams = new URLSearchParams(location.search);
  const isEditing = searchParams.has('generationType');

  const [similarImagesLocalError, setSimilarImagesLocalError] = useState(null);

  useEffect(() => {
    setGeneratedImages([]);
    setDisplayMode(STUDIO_DISPLAY.PENDING);
    setSelectedGeneratedImage(-1);
    // setReferencePattern(null);
    // setReferenceImage(null);
    // setReferenceSketch(null);
    // setReferenceOtherImage(null);
  }, [isEditing]);

  useEffect(() => {
    if (searchParams.has('isGenerating')) {
      setGeneratedImages([]);
    }
  }, [searchParams.has('isGenerating')]);

  useEffect(() => {
    setSimilarImagesLocalError(similarImagesError);
  }, [similarImagesData]);

  const validParams = [
    'isEditing',
    'taskId',
    'itemId',
    'itemType',
    'isGenerating',
  ];

  const areRequiredParamsMissing = validParams.some((param) =>
    searchParams.has(param),
  );

  useEffect(() => {
    if (
      !areRequiredParamsMissing &&
      !location.pathname.includes('studio/image-preview')
    ) {
      navigate('/studio/home');
    }
  }, [areRequiredParamsMissing]);

  const { loading: fashionWeekItemLoading } = useQuery(FASHION_WEEK_ITEM, {
    variables: {
      itemId: searchParams.get('itemId'),
    },
    skip:
      searchParams.get('itemType') !== 'fashionWeekItem' ||
      !searchParams.has('itemId'),
    onCompleted: (data) => {
      const imageToLoad = new window.Image();
      imageToLoad.src = data.fashionWeekItem.photo.image.url;
      imageToLoad.onload = () => {
        setReferenceImage({
          ...data.fashionWeekItem.photo.image,
          width: imageToLoad.naturalWidth,
          height: imageToLoad.naturalHeight,
          studioItemId: data.fashionWeekItem.photo.id,
          entityType: 'FASHION_WEEK_ITEM',
          entityId: data.fashionWeekItem.photo.id,
        });

        setStudioTask({
          ...studioTask,
          status: 'COMPLETED',
          images: [
            {
              ...data.fashionWeekItem.photo.image,
              studioItemId: data.fashionWeekItem.photo.id,
              entityType: 'FASHION_WEEK_ITEM',
              entityId: data.fashionWeekItem.photo.id,
              width: imageToLoad.naturalWidth,
              height: imageToLoad.naturalHeight,
            },
          ],
        });
      };

      setSelectedGeneratedImage(-1);
      setRefSoloItemType('APPAREL');
      if (searchParams.get('generationType') === 'pattern') {
        setRefSoloItemType('PATTERN');
      }
      setDisplayMode(STUDIO_DISPLAY.PREVIEW);
    },
  });

  const { loading: socialMediaItemLoading } = useQuery(SOCIAL_MEDIA_ITEM, {
    variables: {
      postId: searchParams.get('itemId'),
    },
    skip:
      searchParams.get('itemType') !== 'socialMediaItem' ||
      !searchParams.has('itemId'),
    onCompleted: (data) => {
      setStudioTask({
        ...studioTask,
        status: 'COMPLETED',
        images: [
          {
            ...data.post.image,
            studioItemId: data.post.id,
            entityType: 'SOCIAL_MEDIA_ITEM',
            entityId: data.post.id,
          },
        ],
      });

      setReferenceImage({
        entityType: 'SOCIAL_MEDIA_ITEM',
        entityId: data.post.id,
        url: data.post.image.url,
      });

      setSelectedGeneratedImage(-1);
      setRefSoloItemType('APPAREL');
      if (searchParams.get('generationType') === 'pattern') {
        setRefSoloItemType('PATTERN');
      }
      setDisplayMode(STUDIO_DISPLAY.PREVIEW);
    },
  });

  const { loading: uploadItemImageLoading } = useQuery(UPLOAD_ITEM_IMAGE, {
    variables: {
      uploadItemImageId: searchParams.get('itemId'),
    },
    skip:
      searchParams.get('itemType') !== 'fileUploadItem' ||
      !searchParams.has('itemId'),
    onCompleted: (data) => {
      const imageToLoad = new window.Image();
      imageToLoad.src = data.uploadItemImage.node.image.url;
      imageToLoad.onload = () => {
        setStudioTask({
          ...studioTask,
          status: 'COMPLETED',
          images: [
            {
              ...data.uploadItemImage.node.image,
              studioItemId: data.uploadItemImage.node.id,
              entityType: 'FILE_UPLOAD',
              entityId: data.uploadItemImage.node.id,
              width: imageToLoad.naturalWidth,
              height: imageToLoad.naturalHeight,
            },
          ],
        });

        if (data.uploadItemImage.node.fileEntityType === 'PATTERN') {
          setReferencePattern({
            ...data.uploadItemImage.node.image,
            studioItemId: data.uploadItemImage.node.id,
            entityType: 'FILE_UPLOAD',
            entityId: data.uploadItemImage.node.id,
            width: imageToLoad.naturalWidth,
            height: imageToLoad.naturalHeight,
          });
          setRefSoloItemType('PATTERN');
        } else if (data.uploadItemImage.node.fileEntityType === 'APPAREL') {
          setReferenceImage({
            ...data.uploadItemImage.node.image,
            studioItemId: data.uploadItemImage.node.id,
            entityType: 'FILE_UPLOAD',
            entityId: data.uploadItemImage.node.id,
            width: imageToLoad.naturalWidth,
            height: imageToLoad.naturalHeight,
          });
          setRefSoloItemType('APPAREL');
        } else if (data.uploadItemImage.node.fileEntityType === 'SKETCH') {
          setReferenceSketch({
            ...data.uploadItemImage.node.image,
            studioItemId: data.uploadItemImage.node.id,
            entityType: 'FILE_UPLOAD',
            entityId: data.uploadItemImage.node.id,
            width: imageToLoad.naturalWidth,
            height: imageToLoad.naturalHeight,
          });
          setRefSoloItemType('SKETCH');
        } else if (data.uploadItemImage.node.fileEntityType === 'GRAPHIC') {
          setReferenceGraphic({
            ...data.uploadItemImage.node.image,
            studioItemId: data.uploadItemImage.node.id,
            entityType: 'FILE_UPLOAD',
            entityId: data.uploadItemImage.node.id,
            width: imageToLoad.naturalWidth,
            height: imageToLoad.naturalHeight,
          });
          setRefSoloItemType('GRAPHIC');
        }
      };

      setSelectedGeneratedImage(-1);
      setDisplayMode(STUDIO_DISPLAY.PREVIEW);
    },
  });

  const { loading: studioItemLoading } = useQuery(GET_STUDIO_ITEM, {
    variables: {
      id: searchParams.get('itemId'),
    },
    skip:
      searchParams.get('itemType') !== 'studioItem' ||
      !searchParams.has('itemId'),
    onCompleted: (data) => {
      setStudioTask({
        ...studioTask,
        status: 'COMPLETED',
        images: [
          {
            ...data.studioItem.node.image,
            width: data.studioItem.node.image.width,
            height: data.studioItem.node.image.height,
            studioItemId: data.studioItem.node.id,
            entityType: 'STUDIO_ITEM',
            entityId: data.studioItem.node.id,
            generateTaskType: data.studioItem.node.generateTaskType,
          },
        ],
      });

      setSelectedGeneratedImage(-1);
      setDisplayMode(STUDIO_DISPLAY.PREVIEW);

      if (
        [
          'GENERATE_APPAREL',
          'IMAGE_BACKGROUND_REMOVER',
          'ZOOM_IN_IMAGE',
          'CREATE_VARIANTS',
        ].includes(data.studioItem.node.generateTaskType)
      ) {
        setReferenceImage({
          ...data.studioItem.node.image,
          studioItemId: data.studioItem.node.id,
          entityType: 'STUDIO_ITEM',
          entityId: data.studioItem.node.id,
        });
        setRefSoloItemType('APPAREL');
        if (searchParams.get('generationType') === 'pattern') {
          setRefSoloItemType('PATTERN');
        }
      } else if (
        ['GENERATE_PATTERN', 'PATTERN_TILE'].includes(
          data.studioItem.node.generateTaskType,
        )
      ) {
        setReferencePattern({
          ...data.studioItem.node.image,
          studioItemId: data.studioItem.node.id,
          entityType: 'STUDIO_ITEM',
          entityId: data.studioItem.node.id,
        });
        setRefSoloItemType('PATTERN');
      } else if (data.studioItem.node.generateTaskType === 'realize_sketch') {
        setReferenceSketch({
          ...data.studioItem.node.image,
          studioItemId: data.studioItem.node.id,
          entityType: 'STUDIO_ITEM',
          entityId: data.studioItem.node.id,
        });
        setReferenceImage({
          ...data.studioItem.node.image,
          studioItemId: data.studioItem.node.id,
          entityType: 'STUDIO_ITEM',
          entityId: data.studioItem.node.id,
        });
        setRefSoloItemType('SKETCH');
      }
    },
  });

  const isMobile = useMediaQuery({ size: 'md' });

  const shouldShowSimilarImages = !(
    studioTaskData?.studioTask?.generateTaskType ===
      'IMAGE_BACKGROUND_REMOVER' || searchParams.has('generationType')
  );

  const isLoading =
    similarImagesLoading ||
    socialMediaItemLoading ||
    uploadItemImageLoading ||
    fashionWeekItemLoading ||
    studioItemLoading;

  return (
    <div className="flex h-full w-full flex-row items-start justify-start">
      {/* Sidebar */}
      <StudioLeftSidebar />
      {/* Complement of Sidebar */}
      <div className="relative grid h-full w-full grid-cols-[1fr,7rem] overflow-hidden">
        {/* Main Section */}
        <div className="flex flex-col overflow-auto border-r border-secondary/50 bg-[#FAF8F8] py-6">
          {/* Home Page */}
          {isHome && <StudioHome handleChange={updateCreateItemDetails} />}
          {/* Create an Apparel Page */}
          {!isHome && (
            <div
              className={cn(
                'grid h-max grid-cols-[1fr,0.8fr] px-20 sm:px-4 md:px-8 lg:px-20',
                openTools && 'grid-cols-1',
                isMobile && 'grid-cols-1',
              )}
            >
              <div
                className={cn(
                  'grid h-full grid-cols-1 md:grid-cols-[1fr,0.7fr]',
                  !openTools && 'grid-cols-1 md:grid-cols-1',
                )}
              >
                <div className="relative top-0 h-max md:sticky">
                  <ImageDisplay
                    generatedImages={
                      studioTask?.images?.length > 0 ? studioTask?.images : []
                    }
                    orientation={orientation}
                    downloadFile={downloadFile}
                  />
                </div>
                {activeModal === null && openTools && <StudioTools />}
              </div>

              {shouldShowSimilarImages && (
                <div className="container mx-auto h-full w-full p-6">
                  <div
                    className={cn('flex w-full flex-col gap-y-6')}
                    id="more-ideas"
                  >
                    <h2 className="text-2xl font-bold leading-6 text-primary">
                      More ideas to explore
                    </h2>

                    <SimilarImagesGrid
                      loading={isLoading || studioTask?.status === 'PENDING'}
                      error={similarImagesLocalError}
                      images={similarImagesData?.studioSimilarImages}
                      openTools={openTools}
                      navigate={navigate}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {/* AsideBar */}
        <AsideBar activeModal={activeModal} setActiveModal={setActiveModal} />
        {/* Collection Modal */}
        {activeModal === 'collections' && (
          <Collections setActiveModal={setActiveModal} />
        )}
        {activeModal === 'history' && (
          <StudioHistorySidebar
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
        {activeModal === 'designs' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
        {activeModal === 'patterns' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
        {activeModal === 'sketches' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
        {activeModal === 'graphics' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
      </div>
    </div>
  );
};

const SimilarImagesGrid = ({ loading, error, images, openTools, navigate }) => {
  if (error) {
    return (
      <div className="flex w-full items-center justify-center rounded-lg bg-gray-50">
        <div className="text-center">
          <p className="text-base font-semibold text-gray-900">
            Failed to load more ideas
          </p>
          <p className="mt-1 text-sm text-gray-500">
            Please try refreshing the page
          </p>
        </div>
      </div>
    );
  }

  if (loading || images === undefined) {
    return (
      <div
        className={cn(
          `mb-20 grid w-full max-w-[60rem] grid-cols-3 gap-2 sm:grid-cols-4 md:grid-cols-3 md:gap-4 lg:gap-6`,
          openTools && 'grid-cols-4',
        )}
      >
        {Array.from({ length: 18 }).map((_, index) => (
          <div
            key={index}
            className="aspect-[3/4] animate-pulse rounded bg-gray-200"
          />
        ))}
      </div>
    );
  }

  if (!images?.length) {
    return (
      <div className="flex w-full items-start justify-start rounded-lg bg-gray-50">
        <p className="text-base font-bold text-black">
          No similar images found
        </p>
      </div>
    );
  }

  return (
    <div
      className={cn(
        `mb-20 grid w-full max-w-[60rem] grid-cols-3 gap-2 sm:grid-cols-4 md:grid-cols-3 md:gap-4 lg:gap-6`,
        location.pathname.includes('image-preview') && 'mb-0',
        openTools && 'grid-cols-4',
      )}
    >
      {images.map((data, index) => {
        const startIndex = data.node.image.url.indexOf('/v3');
        const endIndex = data.node.image.url.indexOf('.jpg') + 4;
        const extractedPath = data.node.image.url.slice(startIndex, endIndex);
        const newUrl = `https://assets.tfashion.ai${extractedPath}?format=auto`;

        return (
          <div
            key={index}
            className="aspect-[3/4] cursor-pointer rounded transition-transform hover:scale-105 hover:shadow"
            onClick={() =>
              navigate(`/studio/image-preview/studioItem/${data.node.id}`)
            }
          >
            <img
              src={
                import.meta.env.VITE_APP_ENV === 'development'
                  ? newUrl
                  : data.node.image.url
              }
              onError={(e) => {
                if (e.target.src !== data.node.image.url) {
                  e.target.src = data.node.image.url;
                }
                e.target.onerror = null;
              }}
              alt={data.node.image.studioItemId}
              className="h-full w-full rounded object-cover"
            />
          </div>
        );
      })}
    </div>
  );
};
