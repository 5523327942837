import React from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { SignOutIcon } from '@primer/octicons-react';
import { usePostHog } from 'posthog-js/react';

import { ME } from '@/api/authentication';

const SmileIcon = ({ isActive }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      className="group-hover:stroke-primary"
      stroke={isActive ? '#C29B77' : '#282928'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14C8 14 9.5 16 12 16C14.5 16 16 14 16 14"
      className="group-hover:stroke-primary"
      stroke={isActive ? '#C29B77' : '#282928'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9H9.01"
      className="group-hover:stroke-primary"
      stroke={isActive ? '#C29B77' : '#282928'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 9H15.01"
      className="group-hover:stroke-primary"
      stroke={isActive ? '#C29B77' : '#282928'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CreditCardIcon = ({ isActive }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="group-hover:stroke-primary"
      d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z"
      stroke={isActive ? '#C29B77' : '#282928'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="group-hover:stroke-primary"
      d="M1 10H23"
      stroke={isActive ? '#C29B77' : '#282928'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const TeamIcon = ({ isActive }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="group-hover:stroke-primary"
      d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
      stroke={isActive ? '#C29B77' : '#282928'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="group-hover:stroke-primary"
      d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
      stroke={isActive ? '#C29B77' : '#282928'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="group-hover:stroke-primary"
      d="M23 20.9989V18.9989C22.9993 18.1126 22.7044 17.2517 22.1614 16.5512C21.6184 15.8508 20.8581 15.3505 20 15.1289"
      stroke={isActive ? '#C29B77' : '#282928'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="group-hover:stroke-primary"
      d="M16 3.12891C16.8604 3.34921 17.623 3.84961 18.1676 4.55122C18.7122 5.25283 19.0078 6.11574 19.0078 7.00391C19.0078 7.89208 18.7122 8.75499 18.1676 9.4566C17.623 10.1582 16.8604 10.6586 16 10.8789"
      stroke={isActive ? '#C29B77' : '#282928'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// const ActivityIcon = ({ isActive }) => (
//   <svg
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       className="group-hover:stroke-primary"
//       d="M22 12H18L15 21L9 3L6 12H2"
//       stroke={isActive ? '#C29B77' : '#282928'}
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     />
//   </svg>
// )

const Sidebar = ({ data }) => {
  const { pathname } = useLocation();

  const posthog = usePostHog();

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    window.location.href = '/';
    datadogRum.removeUser();
    posthog.reset();
  };

  return (
    <div className="flex h-full min-w-[340px] flex-col">
      <h5 className="mb-[65px] text-[36px] font-semibold  leading-[42px] ">
        Hi, <br /> {data?.me?.fullName}
      </h5>
      <div className="flex h-full flex-col justify-between">
        <div className="space-y-[42px]">
          <NavLink
            to="/profile/account-details"
            className={({ isActive }) =>
              (isActive ? 'text-primary' : '') +
              ' flex items-center space-x-[12px] cursor-pointer group'
            }
          >
            <SmileIcon isActive={pathname.includes('account-details')} />
            <span className="pt-[2.8px] font-[24px] leading-[28px] group-hover:text-primary">
              Account Details
            </span>
          </NavLink>
          {data?.me?.organizationRole === 'ADMIN' ||
          data?.me?.role === 'admin' ? (
            <>
              <NavLink
                to="/profile/payment"
                className={({ isActive }) =>
                  (isActive ? 'text-primary' : '') +
                  ' flex items-center space-x-[12px] cursor-pointer group'
                }
              >
                <CreditCardIcon isActive={pathname.includes('payment')} />
                <span className="pt-[2.8px] font-[24px] leading-[28px] group-hover:text-primary">
                  Payment
                </span>
              </NavLink>
              <NavLink
                to="/profile/plans"
                className={({ isActive }) =>
                  (isActive ? 'text-primary' : '') +
                  ' flex items-center space-x-[12px] cursor-pointer group'
                }
              >
                <CreditCardIcon isActive={pathname.includes('plans')} />
                <span className="pt-[2.8px] font-[24px] leading-[28px] group-hover:text-primary">
                  Plans
                </span>
              </NavLink>
              <NavLink
                to="/profile/team"
                className={({ isActive }) =>
                  (isActive ? 'text-primary' : '') +
                  ' flex items-center space-x-[12px] cursor-pointer group'
                }
              >
                <TeamIcon isActive={pathname.includes('team')} />
                <span className="pt-[2.8px] font-[24px] leading-[28px] group-hover:text-primary">
                  Team
                </span>
              </NavLink>
            </>
          ) : null}
          {/* <NavLink
            to="/profile/activity"
            className={({ isActive }) =>
              (isActive ? 'text-primary' : '') +
              ' flex items-center space-x-[12px] cursor-pointer group'
            }
          >
            <ActivityIcon isActive={pathname.includes('activity')}/>
            <span
              className="pt-[2.8px] font-[24px] leading-[28px] group-hover:text-primary">
              Activity
            </span>
          </NavLink> */}
        </div>
        <div>
          <div
            className={'group flex cursor-pointer items-center space-x-[12px]'}
            onClick={() => handleLogout()}
          >
            <SignOutIcon size={24} className={'group-hover:text-primary'} />
            <span className="pt-[2.8px] font-[24px] leading-[28px] group-hover:text-primary">
              Log out
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Profile = () => {
  const { data } = useQuery(ME);

  return (
    <div className="flex h-full w-full px-[60px] py-[52px]">
      <Sidebar data={data} />
      <div className="mb-12 flex max-w-[876px]">
        <Outlet />
      </div>
    </div>
  );
};
