import { Outlet } from 'react-router-dom';

import { Container } from '@/components';

import { StudioLayout } from './StudioLayout';

export const Studio = () => {
  return (
    <div className="h-full w-full">
      <Container className={'mt-0 flex h-full max-h-full p-0'} mainPage>
        <StudioLayout />
      </Container>

      <Outlet />
    </div>
  );
};
