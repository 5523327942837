import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
  query Locations($name: String) {
    locations(name: $name) {
      id
      type
      geonamesId
      name
    }
  }
`;

export const GET_MARKETS_AND_SEASONS_AND_COLORS = gql`
  query MarketsAndSeasonsAndColors {
    exploreFilters {
      seasons
      colors {
        hex
        name
      }
    }
  }
`;

export const GET_PATTERNS = gql`
  query Patterns($apparels: [String]) {
    explorePatterns: exploreFilters {
      patterns(apparels: $apparels)
    }
  }
`;

export const GET_TREND_FILTERS = gql`
  query Filters(
    $apparels: [String]
    $colors: [String]
    $patterns: [String]
    $audience: String
  ) {
    trendFilters(
      apparels: $apparels
      colors: $colors
      patterns: $patterns
      audience: $audience
    ) {
      apparels {
        label
        value
      }
      colors {
        hex
        label
      }
      patterns {
        label
        value
      }
    }
  }
`;

export const GET_MARKETS = gql`
  query Markets {
    markets {
      id
      apparels {
        label
        value
      }
      audiences {
        id
        name
        ageMax
        ageMin
        gender
        groupId
        groups {
          audienceId
          platform
          features
        }
      }
      location {
        name
        type
        locationId
        countryCode
      }
      plan {
        scope {
          permissions
        }
      }
    }
  }
`;

export const GET_TREND_COLOR_FILTERS = gql`
  query TrendColorFilters(
    $apparels: [String]
    $season: String
    $color: String
    $market: String
    $audience: String
    $pattern: String
    $category: String
  ) {
    trendFilters(
      apparels: $apparels
      season: $season
      color: $color
      market: $market
      audience: $audience
      pattern: $pattern
      category: $category
    ) {
      colors {
        hex
        name
        main
        code
      }
    }
  }
`;

export const GET_TREND_PATTERN_FILTERS = gql`
  query TrendPatternFilters(
    $apparels: [String]
    $season: String
    $color: String
    $market: String
    $audience: String
    $pattern: String
    $category: String
  ) {
    trendFilters(
      apparels: $apparels
      season: $season
      color: $color
      market: $market
      audience: $audience
      pattern: $pattern
      category: $category
    ) {
      patterns {
        label
        value
      }
    }
  }
`;

export const GET_TREND_APPAREL_FILTERS = gql`
  query TrendApparelFilters(
    $apparels: [String]
    $season: String
    $color: String
    $market: String
    $audience: String
    $pattern: String
    $category: String
  ) {
    trendFilters(
      apparels: $apparels
      season: $season
      color: $color
      market: $market
      audience: $audience
      pattern: $pattern
      category: $category
    ) {
      apparels {
        label
        value
      }
    }
  }
`;

export const GET_TREND_FABRIC_FILTERS = gql`
  query TrendFabricFilters(
    $apparels: [String]
    $season: String
    $color: String
    $market: String
    $audience: String
    $pattern: String
    $category: String
  ) {
    trendFilters(
      apparels: $apparels
      season: $season
      color: $color
      market: $market
      audience: $audience
      pattern: $pattern
      category: $category
    ) {
      fabrics {
        label
        value
      }
    }
  }
`;
export const GET_EXPLORE_APPAREL_FILTERS = gql`
  query ExploreApparelFilters(
    $apparels: [String]
    $season: String
    $audiences: [String]
    $market: String
  ) {
    exploreFilters {
      apparels(
        apparels: $apparels
        season: $season
        audiences: $audiences
        market: $market
      ) {
        label
        value
        id
        items {
          label
          id
          items {
            type
            value
          }
        }
      }
    }
  }
`;

export const GET_EXPLORE_COLOR_FILTERS = gql`
  query ExploreColorFilters(
    $audiences: [String]
    $filters: ExploreFilterInput
  ) {
    exploreFilters {
      colors(audiences: $audiences, filters: $filters) {
        label
        value
      }
    }
  }
`;

export const GET_EXPLORE_FILTERS = gql`
  query ExploreFilters($audiences: [String], $filters: ExploreFilterInput) {
    exploreFilters {
      colors(audiences: $audiences, filters: $filters) {
        label
        value
      }
      apparels(audiences: $audiences) {
        label
        value
      }
    }
  }
`;

export const GET_EXPLORE_PATTERN_FILTERS = gql`
  query ExplorePatternFilters($apparels: [String]) {
    exploreFilters {
      patterns(apparels: $apparels) {
        label
        value
      }
    }
  }
`;

export const GET_EXPLORE_TONE_FILTERS = gql`
  query Tones($toneName: String) {
    exploreFilters {
      tones(toneName: $toneName) {
        label
        value
        hex
      }
    }
  }
`;

export const CHECK_MARKET_WITH_LOCATION = gql`
  query CheckMarketWithLocation($location: String) {
    checkMarketWithLocation(location: $location) {
      exists
      market {
        id
      }
      location
    }
  }
`;

// export const FASHION_WEEKS = gql``

// export const FASHION_ITEM_FILTERS = gql``
