import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Observable } from '@apollo/client/utilities';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';

import store from '@/store';

import { GENERATE_ACCESS_TOKEN } from './api/authentication';
import { router } from './routes/router';
import branchDeployConfig from './branch-deploy.config.json';

import './index.css';
import 'swiper/css';

const apiOriginUrl =
  branchDeployConfig.isFeatureBranch === true
    ? branchDeployConfig.branchGraphqlUrl
    : import.meta.env.VITE_API_BASE_URL;

datadogRum.init({
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.eu',
  service: 't-fashion-client',
  env: import.meta.env.VITE_APP_ENV || 'prod',
  version: localStorage.getItem('appVersion'),
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingOrigins: [apiOriginUrl],
});

datadogRum.startSessionReplayRecording();

Sentry.init({
  dsn: 'https://14924fc3e825bdc09304c6184162a9cc@o4506019323183104.ingest.sentry.io/4506019443900416',
  integrations: [new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend: (event) => {
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    ) {
      return null;
    }
    return event;
  },
});

const httpLink = new BatchHttpLink({
  uri: apiOriginUrl,
  batchMax: 10,
  batchInterval: 30,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

function customMerge(existing, incoming, { readField }) {
  const data = existing ? { ...existing.data } : {};
  incoming.data.forEach((item) => {
    data[readField('id', item)] = item;
  });

  return {
    data,
    cursor: incoming.cursor,
    hasNextPage: incoming.hasNextPage || null,
  };
}

function customRead(existing) {
  if (existing) {
    return {
      data: Object.values(existing.data),
      cursor: existing.cursor,
      hasNextPage: existing.hasNextPage || null,
    };
  }
}

function cursorMerge(existing, incoming, { readField }) {
  const edges = existing ? { ...existing.edges } : {};

  incoming.edges.forEach((item) => {
    edges[readField('id', item.node)] = item;
  });

  return {
    edges,
    pageInfo: incoming.pageInfo,
  };
}

function cursorRead(existing) {
  if (existing) {
    return {
      edges: Object.values(existing.edges),
      pageInfo: existing?.pageInfo,
    };
  }
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        // eslint-disable-next-line default-case
        switch (err.extensions.code) {
          case 'AUTH_TOKEN_ERROR':
            localStorage.removeItem('accessToken');
            // ignore 401 error for a refresh request
            if (operation.operationName === 'GenerateAccessToken') return;
            // eslint-disable-next-line no-case-declarations
            const observable = new Observable((observer) => {
              // used an annonymous function for using an async function
              (async () => {
                try {
                  const accessToken = await refreshToken();
                  if (!accessToken) {
                    // eslint-disable-next-line new-cap
                    throw new graphQLErrors('Empty Access Token');
                  }
                  // Retry the failed request
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };
                  forward(operation).subscribe(subscriber);
                } catch (err) {
                  observer.error(err);
                }
              })();
            });
            return observable;
        }
      }
    }
    // eslint-disable-next-line no-console
    if (networkError) console.log(`[Network error]: ${networkError}`);
  },
);

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  addTypename: false,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          exploreFilters: {
            keyArgs: false,
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
          explore: {
            keyArgs: false,
            merge: customMerge,
            read: customRead,
          },
          trendItems: {
            keyArgs: false,
            merge: customMerge,
            read: customRead,
          },
          tones: {
            keyArgs: false,
            merge: customMerge,
            read: customRead,
          },
          patterns: {
            keyArgs: false,
            merge: customMerge,
            read: customRead,
          },
          fabrics: {
            keyArgs: false,
            merge: customMerge,
            read: customRead,
          },
          collectionItemsMerged: {
            keyArgs: false,
            merge: customMerge,
            read: customRead,
          },
          studioTaskHistory: {
            keyArgs: ['type'],
            merge: cursorMerge,
            read: cursorRead,
          },
          getInspiredImages: {
            keyArgs: ['type'],
            merge: cursorMerge,
            read: cursorRead,
          },
          exploreFashionWeekItems: {
            keyArgs: ['type'],
            merge: cursorMerge,
            read: cursorRead,
          },
          snapshotItems: {
            keyArgs: ['filters'],
            merge: cursorMerge,
            read: cursorRead,
          },
          studioExplore: {
            keyArgs: ['generationType', 'entityType'],
            merge: cursorMerge,
            read: cursorRead,
          },
        },
      },
    },
  }),
});

// Request a refresh token to then stores and returns the tokens.
const refreshToken = async () => {
  try {
    const refreshResolverResponse = await client.mutate({
      mutation: GENERATE_ACCESS_TOKEN,
      variables: {
        refreshToken: localStorage.getItem('refreshToken'),
      },
    });
    const { accessToken, refreshToken } =
      refreshResolverResponse.data?.generateAccessToken;
    localStorage.setItem('accessToken', accessToken || '');
    localStorage.setItem('refreshToken', refreshToken || '');
    return accessToken;
  } catch (err) {
    localStorage.clear();
    window.location.replace(`${import.meta.env.BASE_URL}login`);
    throw err;
  }
};

const posthogApiKey = import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY;
const posthogOptions = {
  api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST,
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PostHogProvider apiKey={posthogApiKey} options={posthogOptions}>
        <RouterProvider router={router} />
      </PostHogProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
);
