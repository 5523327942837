import { useEffect, useMemo, useRef, useState } from 'react';
import { X } from 'lucide-react';
import { findBestMatch } from 'string-similarity';

import { FormLabel } from '@/components/FormInput';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { cn } from '@/helpers/utils';

import { Checkbox } from '../ui/checkbox';

export function DropdownSelectItem({
  filter,
  filterKey,
  onChange,
  closeOnChange = true,
  isClearable = false,
  variant = 'default',
  isExcludable = false,
}) {
  const triggerRef = useRef(null);
  const [filterPlaceholder, setFilterPlaceholder] = useState(
    filter.placeholder,
  );

  useEffect(() => {
    if (filter.isMulti && filter.value?.length > 0) {
      setFilterPlaceholder(`${filter.value?.length} ${filter.label} selected`);
    } else {
      setFilterPlaceholder(filter.placeholder);
    }
  }, [filter.value]);

  useEffect(() => {
    function handleFocus(e) {
      if (e.keyCode === 32) {
        e.preventDefault();
        setSearch((prev) => prev + ' ');
      }
    }

    triggerRef.current.addEventListener('keydown', handleFocus);

    return () => {
      if (triggerRef.current) {
        triggerRef.current.removeEventListener('keydown', handleFocus);
      }
    };
  }, [triggerRef]);

  const [search, setSearch] = useState('');
  const searchData = useMemo(() => {
    if (!search || search === '') {
      return filter?.options;
    }

    const ratings = findBestMatch(
      search,
      filter?.options?.map((option) => option.label),
    )
      .ratings.sort((a, b) => b.rating - a.rating)
      .filter((rating) => rating.rating > 0.2)
      .map((rating) =>
        filter?.options?.find((option) => option.label === rating.target),
      );

    const filteredOptions = filter?.options?.filter((option) => {
      const labelIncludesSearch = option.label
        .toLowerCase()
        .includes(search.toLowerCase().trim());

      if (option.options && option.options.length > 0) {
        return (
          labelIncludesSearch ||
          option.options.some((subOption) =>
            subOption.label.toLowerCase().includes(search.toLowerCase().trim()),
          )
        );
      }
      return labelIncludesSearch;
    });

    const finalOptions = [
      ...new Set([...filteredOptions, ...ratings].map((opt) => opt.value)),
    ].map((value) =>
      [...filteredOptions, ...ratings].find((opt) => opt.value === value),
    );

    return finalOptions;
  }, [filter.options, search]);

  const isGroup = searchData && searchData[0]?.options;

  const handleClear = (e) => {
    e.stopPropagation();
    onChange(null, filterKey);
    setSearch('');
  };

  return (
    <AccordionItem value={filterKey}>
      <FormLabel>{filter?.label}</FormLabel>
      <AccordionTrigger
        ref={triggerRef}
        className={cn(
          'flex items-center gap-6 mt-2 border rounded-md border-[#1A1A1A] bg-white px-4 py-2 text-[.875rem] leading-5 outline-none font-normal [&_div]:data-[state=open]:hidden [&_div]:data-[state=closed]:flex [&_input]:data-[state=open]:block [&_input]:data-[state=closed]:hidden',
          filter.value ? 'text-charcoal' : 'text-[#A1A1A1]',
          Array.isArray(filter.value) && !filterPlaceholder.includes('selected')
            ? 'py-1.5'
            : 'py-2',
          isClearable &&
            (Array.isArray(filter.value)
              ? filter.value.length > 0
              : Boolean(filter.value))
            ? '[&>svg]:hidden'
            : '',
        )}
      >
        <div className="flex grow items-center gap-2 overflow-hidden">
          {filter.value?.length > 0 && (
            <div className="flex items-center gap-2 overflow-hidden">
              {filterKey === 'attribute.custom' ||
              (filter.isMulti && filterKey !== 'market') ? (
                <div className="flex items-center gap-2 overflow-hidden">
                  {(() => {
                    const maxVisibleItems =
                      filterKey === 'attribute.custom' ? 1 : 3;
                    const visibleItems =
                      filter.value.length > maxVisibleItems
                        ? filter.value.slice(
                            0,
                            filterKey === 'attribute.custom' ? 1 : 2,
                          )
                        : filter.value.slice(0, maxVisibleItems);
                    const remainingItemCount =
                      filter.value.length > maxVisibleItems
                        ? filter.value.length -
                          (filterKey === 'attribute.custom' ? 1 : 2)
                        : 0;

                    return (
                      <>
                        {visibleItems.map((v, idx) => (
                          <div
                            key={idx}
                            className="flex w-max shrink-0 items-center gap-1 rounded border border-backgroundBorder bg-background p-1"
                            onClick={(e) => {
                              e.stopPropagation();
                              onChange(v, filterKey);
                            }}
                          >
                            {filterKey === 'color' && (
                              <h3
                                className="block h-3.5 w-3.5 rounded-full"
                                style={{ backgroundColor: v.hex }}
                              />
                            )}
                            <span className="text-[0.725rem] font-semibold leading-4 text-charcoal">
                              {v?.label}
                            </span>
                            <X
                              size={12}
                              strokeWidth={2.5}
                              fill="black"
                              className="text-charcoal"
                            />
                          </div>
                        ))}
                        {remainingItemCount > 0 && (
                          <span className="ml-2 text-sm font-semibold text-primary">
                            +{remainingItemCount} more
                          </span>
                        )}
                      </>
                    );
                  })()}
                </div>
              ) : filterKey === 'market' ? (
                <span className="max-w-fit text-left text-[0.875rem] capitalize leading-5 line-clamp-1">
                  {`${filter.value?.length} ${filter.label} selected`}
                </span>
              ) : null}
            </div>
          )}

          {!filter.value?.length && (
            <span className="max-w-fit text-left text-[0.875rem] capitalize leading-5 line-clamp-1">
              {filter.value?.label || filterPlaceholder}
            </span>
          )}
        </div>
        <input
          className="w-full text-black outline-none"
          type="text"
          value={search}
          placeholder={filterPlaceholder}
          onClick={(e) => e.preventDefault()}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSearch(e.target.value);
          }}
        />
        {isClearable &&
          (Array.isArray(filter.value)
            ? filter.value.length > 0
            : Boolean(filter.value)) && (
            <button
              onClick={handleClear}
              className="pr-1 font-bold text-charcoal hover:text-[#000]"
            >
              ✕
            </button>
          )}
      </AccordionTrigger>
      <AccordionContent
        className={cn(
          'rounded-md right-0 !z-[999] mt-2 w-full min-w-[230px] bg-[#F7F7F7]  outline-none p-2 max-h-96 overflow-y-auto',
        )}
      >
        {!searchData?.length ? (
          <div className="p-2 text-center text-sm text-black">
            No results for the given filter or search term.
          </div>
        ) : isGroup ? (
          <Accordion
            collapsible
            type="single"
            className="flex flex-col gap-2"
            defaultValue={
              filter.value?.label || filter.value?.location
                ? searchData
                    .find((option) =>
                      option.options?.some((subOption) =>
                        filter.value.location
                          ? subOption.location === filter.value.location
                          : option.label === filter.value.type,
                      ),
                    )
                    ?.label?.toLowerCase()
                    .replace(/\s/g, '-')
                : variant !== 'default'
                  ? searchData[0]?.label.toLowerCase().replace(/\s/g, '-')
                  : ''
            }
          >
            {searchData.map((option, index) => (
              <AccordionItem
                key={index}
                value={option.label.toLowerCase().replace(/\s/g, '-')}
              >
                <AccordionTrigger className="last-of-type::mb-0 rounded p-2 data-[state=closed]:bg-[#FFFDFD]">
                  {option.label}
                </AccordionTrigger>
                <AccordionContent>
                  {option.options.map((subOption, subIndex) => (
                    <div
                      key={subIndex}
                      className="group flex cursor-pointer items-center justify-between rounded border border-transparent px-2 py-[.625rem] text-[0.875rem] leading-5 transition-all hover:border-[#1A1A1A/5] hover:bg-[#FFFDFD]"
                    >
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          const isExcluded = filter?.excludeValue?.find(
                            (v) => v.value === subOption.value,
                          );
                          if (!isExcluded) {
                            onChange(subOption, filterKey);
                            if (!filter.isMulti && closeOnChange) {
                              triggerRef.current?.click();
                            }
                          }
                        }}
                        className="flex grow items-center gap-2"
                      >
                        {filter.isMulti === true && (
                          <div
                            className={cn(
                              'flex h-4 w-4 items-center justify-center rounded border',
                              filter?.value?.find((v) => {
                                if (filterKey === 'market') {
                                  return v.value === subOption.audVal;
                                } else if (filterKey === 'attribute.custom') {
                                  return v.value === subOption.value;
                                } else {
                                  return false;
                                }
                              })
                                ? 'border-primary bg-primary'
                                : 'border-gray-300',
                              filter?.excludeValue?.find((v) => {
                                if (filterKey === 'market') {
                                  return v.value === subOption.audVal;
                                } else if (filterKey === 'attribute.custom') {
                                  return v.value === subOption.value;
                                } else {
                                  return false;
                                }
                              })
                                ? 'border-primary bg-primary'
                                : 'border-gray-300',
                            )}
                          >
                            {filter?.value?.find((v) => {
                              if (filterKey === 'market') {
                                return v.value === subOption.audVal;
                              } else if (filterKey === 'attribute.custom') {
                                return v.value === subOption.value;
                              } else {
                                return false;
                              }
                            }) && <Checkbox id="filters" checked={true} />}
                            {filter?.excludeValue?.find((v) => {
                              if (filterKey === 'market') {
                                return v.value === subOption.audVal;
                              } else if (filterKey === 'attribute.custom') {
                                return v.value === subOption.value;
                              } else {
                                return false;
                              }
                            }) && (
                              <Checkbox
                                id="filterOuts"
                                checked={true}
                                variant="exclude"
                              />
                            )}
                          </div>
                        )}
                        {subOption.label}
                      </div>
                      {isExcludable && (
                        <>
                          {filter?.excludeValue?.find(
                            (v) => v.value === subOption.value,
                          ) ? (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                onChange(subOption, filterKey, false, true);
                              }}
                              className="invisible rounded border border-backgroundBorder bg-white px-1.5 py-0.5 hover:bg-background group-hover:visible"
                            >
                              <span className="text-black">Cancel</span>
                            </div>
                          ) : (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                onChange(subOption, filterKey, false, true);
                              }}
                              className="invisible rounded border border-customRed bg-white px-1.5 py-0.5 hover:bg-customRedGround group-hover:visible"
                            >
                              <span className="text-red">Exclude</span>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          searchData?.map((option, index) => (
            <div
              key={index}
              className="group flex cursor-pointer items-center justify-between rounded border border-transparent px-2 py-[.625rem] text-[0.875rem] leading-5 transition-all hover:border-[#1A1A1A/5] hover:bg-[#FFFDFD]"
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  const isExcluded = filter?.excludeValue?.find(
                    (v) => v.value === option.value,
                  );
                  if (!isExcluded) {
                    onChange(option, filterKey);
                    if (!filter.isMulti && closeOnChange) {
                      triggerRef.current?.click();
                    }
                  }
                }}
                className="flex grow items-center gap-2"
              >
                {filter.isMulti === true && (
                  <div
                    className={cn(
                      'flex h-4 w-4 items-center justify-center rounded border',
                      filter?.value?.find((v) => v.value === option.value)
                        ? 'border-primary bg-primary'
                        : 'border-gray-300',
                      filter?.excludeValue?.find(
                        (v) => v.value === option.value,
                      )
                        ? 'border-primary bg-primary'
                        : 'border-gray-300',
                    )}
                  >
                    {filter?.value?.find((v) => v.value === option.value) && (
                      <Checkbox id="filters" checked={true} />
                    )}
                    {filter?.excludeValue?.find(
                      (v) => v.value === option.value,
                    ) && (
                      <Checkbox
                        id="filterOuts"
                        checked={true}
                        variant="exclude"
                      />
                    )}
                  </div>
                )}
                {option.hex && (
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: option.hex }}
                  />
                )}
                {option.label}
              </div>
              {isExcludable && (
                <>
                  {filter?.excludeValue?.find(
                    (v) => v.value === option.value,
                  ) ? (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(option, filterKey, false, true);
                      }}
                      className="invisible rounded border border-backgroundBorder bg-white px-1.5 py-0.5 hover:bg-background group-hover:visible"
                    >
                      <span className="text-black">Cancel</span>
                    </div>
                  ) : (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(option, filterKey, false, true);
                      }}
                      className="invisible rounded border border-customRed bg-white px-1.5 py-0.5 hover:bg-customRedGround group-hover:visible"
                    >
                      <span className="text-customRed">Exclude</span>
                    </div>
                  )}
                </>
              )}
            </div>
          ))
        )}
      </AccordionContent>
    </AccordionItem>
  );
}

export function DropdownSelect({ children, ...props }) {
  return <Accordion {...props}>{children}</Accordion>;
}
