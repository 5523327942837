/* eslint-disable max-len */

import { useCallback, useEffect, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { RxCross1 } from 'react-icons/rx';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { debounce } from 'lodash';
import numeral from 'numeral';

import {
  FASHION_WEEK_ITEM,
  FASHION_WEEK_SIMILAR_IMAGES,
  POST,
  SOCIAL_MEDIA_SIMILAR_IMAGES,
} from '@/api/explore/queries';

import { Wrapper } from './index';

dayjs.extend(utc);

function Watermark() {
  return (
    <svg
      viewBox="0 0 1024 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="p absolute bottom-0 right-0 w-full"
      style={{ pointerEvents: 'none' }}
    >
      <path
        d="M867 90.5C867 55.4299 895.93 27 931 27H932C967.07 27 997.202 55.4299 997.202 90.5C997.202 125.57 967.57 154 932.5 154H932C896.93 154 867 125.57 867 90.5Z"
        fill="white"
      />
      <path
        d="M983.727 72.2872C985.842 77.974 987 84.1104 987 90.5092C987 120.051 962.422 144 932.104 144C909.501 144 890.095 130.684 881.681 111.67L910.33 85.1534L939.863 114.55L983.727 72.2872ZM931.774 37C950.977 37 967.859 46.5752 977.638 61.0523L939.653 97.4174L910.354 68.4383L877.698 98.4681C877.243 95.7188 877 92.9033 877 90.0297C877 60.7424 901.523 37 931.774 37Z"
        fill="black"
      />
    </svg>
  );
}

function SocialMediaImage(item) {
  const navigate = useNavigate();

  return (
    <div
      className="w-full cursor-pointer"
      key={item.id}
      onClick={() =>
        navigate(`/explore/image-preview/socialMediaItem/${item.id}`)
      }
    >
      <div className="relative">
        <img src={item.image.url} className="rounded-md" />
      </div>

      <div className="mt-[0.625rem]">
        <div className="mb-1.5 flex items-center justify-between">
          <h1 className="text-[0.813rem] font-semibold leading-none text-primary">
            @{item.influencer.username || item.influencer.instagramUsername}
          </h1>
          <span className="text-xs font-semibold text-[#A1A1A1]">
            ❤ {numeral(item.likes).format('0a')}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-[0.938rem] font-semibold capitalize leading-none">
            {item.influencer.fullName}
          </span>
          <span className="text-xs font-semibold text-[#A1A1A1]">
            📅 {dayjs(item.publishDate).format('DD MMM YYYY')}
          </span>
        </div>
      </div>
    </div>
  );
}
function FashionWeekImage(item) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [imageWidth, setImageWidth] = useState(item.image.width || 1024);
  const [imageHeight, setImageHeight] = useState(item.image.height || 1536);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const setContainerHeightDebounced = useCallback(
    debounce((height) => setContainerHeight(height), 300),
    [],
  );

  useEffect(() => {
    const imageToLoad = new window.Image();
    imageToLoad.src = item.image.url;
    imageToLoad.onload = () => {
      setImageWidth(imageToLoad.naturalWidth);
      setImageHeight(imageToLoad.naturalHeight);
      setLoading(false);
    };

    return () => setLoading(false);
  }, [item.image.url]);

  useEffect(() => {
    const height = (containerWidth / imageWidth) * imageHeight;
    setContainerHeightDebounced(height);
  }, [containerWidth, imageWidth, imageHeight, setContainerHeightDebounced]);

  const blur = item?.image?.blurhash || 'LKO2?U%2Tw=w]~RBVZRi};RPxuof';

  return (
    <div
      className="w-full cursor-pointer"
      key={item.id}
      onClick={() =>
        navigate(`/explore/image-preview/fashionWeekItem/${item.id}`)
      }
      ref={(el) => el && setContainerWidth(el.offsetWidth)}
    >
      <div className="relative" style={{ height: containerHeight }}>
        {loading && blur ? (
          <div className="h-full w-full overflow-hidden rounded-md bg-slate-400">
            <Blurhash
              hash={blur}
              width={containerWidth}
              height={containerHeight}
              resolutionX={32}
              resolutionY={32}
              punch={1}
            />
          </div>
        ) : (
          <>
            <img
              src={item.image.url}
              className="h-full w-full rounded-md object-cover"
              alt={`${item.city} Fashion Week - ${item.designer}`}
            />
            <Watermark />
          </>
        )}
      </div>

      <div className="mt-[0.625rem]">
        <h1 className="text-[0.813rem] font-semibold capitalize leading-none text-primary">
          {item.city?.replaceAll('-', ' ')} Fashion Week
        </h1>
        <span className="text-[0.938rem] font-semibold uppercase leading-none">
          {item.designer?.replaceAll('-', ' ')}
        </span>
      </div>
    </div>
  );
}

export function ExploreSimilarImages() {
  const [imageLoading, setImageLoading] = useState(true);

  const { type, id } = useParams();

  const navigate = useNavigate();

  const [selectedApparel, setSelectedApparel] = useState(null);

  const [initialLoad, setInitialLoad] = useState(false);
  const [drawing, setDrawing] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [stretching, setStretching] = useState(null);

  const { data: fashionWeekData, loading: fashionWeekDataLoading } = useQuery(
    FASHION_WEEK_ITEM,
    {
      variables: {
        itemId: id,
      },
      skip: type !== 'fashionWeekItem',
    },
  );

  const { data: postItemData, loading: postItemLoading } = useQuery(POST, {
    variables: {
      id,
    },
    skip: type !== 'socialMediaItem',
  });

  const image =
    postItemData?.post?.image || fashionWeekData?.fashionWeekItem.photo.image;

  const [selectedArea, setSelectedArea] = useState({
    x: 0,
    y: 0,
    width: image?.width || 100,
    height: image?.height || 100,
  });

  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [startSelectedArea, setStartSelectedArea] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const { data: exploreData, loading: exploreLoading } = useQuery(
    SOCIAL_MEDIA_SIMILAR_IMAGES,
    {
      variables: {
        apparel: selectedApparel
          ? {
              apparelType: type,
              apparelId: selectedApparel,
            }
          : undefined,
        image: {
          id,
          docType: type,
          ...selectedArea,
        },
      },
      skip:
        !image || drawing === true || dragging === true || stretching !== null,
    },
  );

  const { data: fashionWeekSimilarImagesData, loading: fashionWeekLoading } =
    useQuery(FASHION_WEEK_SIMILAR_IMAGES, {
      variables: {
        apparel: selectedApparel
          ? {
              apparelType: type,
              apparelId: selectedApparel,
            }
          : undefined,
        image: {
          id,
          docType: type,
          ...selectedArea,
        },
      },
      skip:
        !image || drawing === true || dragging === true || stretching !== null,
    });

  const apparels =
    fashionWeekData?.fashionWeekItem.apparels ||
    postItemData?.post.apparels ||
    [];

  useEffect(() => {
    function closePage(e) {
      if (e.key === 'Escape') {
        return navigate(-2);
      }

      return null;
    }

    window.addEventListener('keydown', closePage);

    return () => window.removeEventListener('keydown', closePage);
  }, []);

  useEffect(() => {
    if (image) {
      const imageToLoad = new window.Image();
      imageToLoad.src = image.url;
      imageToLoad.onload = () => {
        setImageLoading(false);
      };

      if (!initialLoad) {
        setSelectedArea({
          x: 0,
          y: 0,
          width: image.width,
          height: image.height,
        });

        setInitialLoad(true);
      }
    }
  }, [image, initialLoad]);

  const onMouseDown = (event) => {
    const svg = event.currentTarget;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    const ctm = svg.getScreenCTM().inverse();
    const svgPoint = point.matrixTransform(ctm);

    if (
      selectedArea.x > 0 &&
      selectedArea.y > 0 &&
      (Math.abs(svgPoint.x - selectedArea.x) < 15 ||
        Math.abs(svgPoint.x - (selectedArea.x + selectedArea.width)) < 15 ||
        Math.abs(svgPoint.y - selectedArea.y) < 15 ||
        Math.abs(svgPoint.y - (selectedArea.y + selectedArea.height)) < 15)
    ) {
      setStretching({
        corner: getCorner(svgPoint, selectedArea),
      });
    } else if (
      selectedArea.x > 0 &&
      selectedArea.y > 0 &&
      svgPoint.x > selectedArea.x &&
      svgPoint.x < selectedArea.x + selectedArea.width &&
      svgPoint.y > selectedArea.y &&
      svgPoint.y < selectedArea.y + selectedArea.height
    ) {
      setDragging(true);
      setStartPoint({
        x: Math.floor(svgPoint.x - selectedArea.x),
        y: Math.floor(svgPoint.y - selectedArea.y),
      });
    } else {
      setDrawing(true);
      setStartPoint({
        x: Math.floor(svgPoint.x),
        y: Math.floor(svgPoint.y),
      });
      setSelectedArea({
        x: Math.floor(svgPoint.x),
        y: Math.floor(svgPoint.y),
        width: 0,
        height: 0,
      });
    }
  };

  const onMouseUp = () => {
    setDrawing(false);
    setDragging(false);
    setStretching(null);
    if (startSelectedArea) {
      setSelectedArea(startSelectedArea);
      setStartSelectedArea(null);
    }
  };

  const onMouseMove = (event) => {
    if (!drawing && !dragging && !stretching) return;

    const svg = event.currentTarget;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    const ctm = svg.getScreenCTM().inverse();
    const svgPoint = point.matrixTransform(ctm);
    console.log({ dragging, drawing, stretching });

    if (dragging) {
      setSelectedArea({
        ...selectedArea,
        x: Math.floor(svgPoint.x - startPoint.x),
        y: Math.floor(svgPoint.y - startPoint.y),
      });
    } else if (drawing) {
      setSelectedArea({
        x: Math.floor(Math.min(startPoint.x, svgPoint.x)),
        y: Math.floor(Math.min(startPoint.y, svgPoint.y)),
        width: Math.floor(Math.abs(startPoint.x - svgPoint.x)),
        height: Math.floor(Math.abs(startPoint.y - svgPoint.y)),
      });
    } else if (stretching) {
      const newSelectedArea = { ...selectedArea };

      switch (stretching.corner) {
        case 'top-left':
          newSelectedArea.x = Math.floor(svgPoint.x);
          newSelectedArea.y = Math.floor(svgPoint.y);
          newSelectedArea.width = Math.floor(
            selectedArea.x + selectedArea.width + -svgPoint.x,
          );
          newSelectedArea.height = Math.floor(
            selectedArea.height + selectedArea.y - svgPoint.y,
          );
          break;
        case 'top-right':
          newSelectedArea.y = Math.floor(svgPoint.y);
          newSelectedArea.width = Math.floor(svgPoint.x - selectedArea.x);
          newSelectedArea.height = Math.floor(
            selectedArea.y + selectedArea.height - svgPoint.y,
          );
          break;
        case 'bottom-left':
          newSelectedArea.x = Math.floor(svgPoint.x);
          newSelectedArea.width = Math.floor(
            selectedArea.x + selectedArea.width - svgPoint.x,
          );
          newSelectedArea.height = Math.floor(svgPoint.y - selectedArea.y);
          break;
        case 'bottom-right':
          newSelectedArea.width = Math.floor(svgPoint.x - selectedArea.x);
          newSelectedArea.height = Math.floor(svgPoint.y - selectedArea.y);
          break;
        default:
          break;
      }
      setStartSelectedArea(newSelectedArea);
    }
  };

  const getCorner = (point, area) => {
    const corners = {
      'top-left': [area.x, area.y],
      'top-right': [area.x + area.width, area.y],
      'bottom-left': [area.x, area.y + area.height],
      'bottom-right': [area.x + area.width, area.y + area.height],
    };

    for (const cornerEl in corners) {
      if (
        Math.abs(point.x - corners[cornerEl][0]) < 15 &&
        Math.abs(point.y - corners[cornerEl][1]) < 15
      ) {
        return cornerEl;
      }
    }

    return null;
  };

  return (
    <Wrapper imagePreview={false}>
      {postItemLoading || fashionWeekDataLoading ? (
        <div className="flex items-center justify-center py-9 px-16">
          <div className="h-full w-full animate-pulse bg-slate-400"></div>
        </div>
      ) : (
        <div className="flex items-center justify-center py-9 px-16">
          <div className="relative w-full">
            {imageLoading ? (
              <Blurhash
                height="100%"
                width="100%"
                hash={image.blurhash || 'LKO2?U%2Tw=w]~RBVZRi};RPxuof'}
                resolutionX={32}
                resolutionY={32}
                punch={1}
                className="border"
              />
            ) : (
              <svg
                className="z-40 w-full border border-white shadow-xl"
                viewBox={`0 0 ${image.width} ${image.height}`}
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio={'xMidYMid meet'}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
              >
                <image
                  href={image?.url}
                  width={image.width}
                  height={image.height}
                  className="pointer-events-none"
                />
                <defs>
                  <mask id="Mask">
                    <rect
                      fill="#fff"
                      height="100%"
                      rx="0"
                      ry="0"
                      width="100%"
                      x="0"
                      y="0"
                    ></rect>
                    <rect
                      height={selectedArea.height}
                      id="hole"
                      rx="0"
                      ry="0"
                      width={selectedArea.width}
                      x={selectedArea.x}
                      y={selectedArea.y}
                      style={{
                        cursor:
                          selectedArea.height > 0 &&
                          selectedArea.height !== image.height
                            ? 'grab'
                            : 'inherit',
                      }}
                    ></rect>
                  </mask>
                </defs>
                <rect
                  height="100%"
                  mask="url(#Mask)"
                  width="100%"
                  x="0"
                  y="0"
                  onClick={() => {
                    setSelectedArea({
                      x: 0,
                      y: 0,
                      width: image.width,
                      height: image.height,
                    });
                    setSelectedApparel(null);
                  }}
                  style={{
                    fill: 'rgba(0, 0, 0, 0.5)',
                  }}
                ></rect>
                <use
                  fill="rgba(0,0,0,0)"
                  href="#hole"
                  stroke="#fff"
                  strokeWidth={2}
                >
                  <rect
                    height={selectedArea.height}
                    id="hole"
                    rx="0"
                    ry="0"
                    width={selectedArea.width}
                    x={selectedArea.x}
                    y={selectedArea.y}
                  ></rect>
                </use>

                {drawing === false && (
                  <>
                    <g
                      transform={`translate(${selectedArea.x - 12}, ${
                        selectedArea.y - 12
                      }), rotate(0)`}
                      className="cursor-nw-resize"
                    >
                      <rect
                        fill="rgba(0,0,0,0)"
                        height={30}
                        width={30}
                        x={0}
                        y={0}
                      />
                      <path
                        d="M24 0H36V12H24C17.37255 12 12 17.37255 12 24V36H0V24C0 10.7452 10.7452 0 30 0Z"
                        fill="#FFF"
                      />
                    </g>

                    <g
                      transform={`translate(${
                        selectedArea.x + selectedArea.width + 12
                      }, ${selectedArea.y - 12}), rotate(90)`}
                      className="cursor-ne-resize"
                    >
                      <rect
                        fill="rgba(0,0,0,0)"
                        height={30}
                        width={30}
                        x={0}
                        y={0}
                      />
                      <path
                        d="M24 0H36V12H24C17.37255 12 12 17.37255 12 24V36H0V24C0 10.7452 10.7452 0 24 0Z"
                        fill="#FFF"
                      />
                    </g>
                    <g
                      transform={`translate(${selectedArea.x - 12}, ${
                        selectedArea.height + selectedArea.y + 12
                      }), rotate(270)`}
                      className="cursor-sw-resize"
                    >
                      <rect
                        fill="rgba(0,0,0,0)"
                        height={30}
                        width={30}
                        x={0}
                        y={0}
                      />
                      <path
                        d="M24 0H36V12H24C17.37255 12 12 17.37255 12 24V36H0V24C0 10.7452 10.7452 0 24 0Z"
                        fill="#FFF"
                      />
                    </g>
                    <g
                      transform={`translate(${
                        selectedArea.width + selectedArea.x + 12
                      }, ${selectedArea.height + selectedArea.y + 12}), rotate(180)`}
                      className="cursor-se-resize"
                    >
                      <rect
                        fill="rgba(0,0,0,0)"
                        height={30}
                        width={30}
                        x={0}
                        y={0}
                      />
                      <path
                        d="M24 0H36V12H24C17.37255 12 12 17.37255 12 24V36H0V24C0 10.7452 10.7452 0 24 0Z"
                        fill="#FFF"
                      />
                    </g>
                  </>
                )}

                <rect
                  fill="rgba(0,0,0,0)"
                  height="20"
                  stroke="none"
                  className="cursor-ns-resize"
                  width={selectedArea.width}
                  x={selectedArea.x}
                  y={selectedArea.y - 10}
                ></rect>

                <rect
                  fill="rgba(0,0,0,0)"
                  height={selectedArea.height}
                  stroke="none"
                  width="20"
                  className="cursor-ew-resize"
                  x={selectedArea.x - 10}
                  y={selectedArea.y - 10}
                ></rect>

                <rect
                  fill="rgba(0,0,0,0)"
                  height="20"
                  stroke="none"
                  width={selectedArea.width}
                  className="cursor-ns-resize"
                  x={selectedArea.x}
                  y={selectedArea.y + selectedArea.height - 5}
                ></rect>

                <rect
                  fill="rgba(0,0,0,0)"
                  height={selectedArea.height}
                  stroke="none"
                  width="20"
                  className="cursor-ew-resize"
                  x={selectedArea.x + selectedArea.width}
                  y={selectedArea.y}
                ></rect>
                {apparels.map((apparel) => {
                  const apparelBox =
                    apparel.originalBox.length > 0
                      ? apparel.originalBox
                      : apparel.box
                        ? apparel.box
                        : [];

                  return (
                    <>
                      {selectedApparel !== apparel.id && (
                        <circle
                          key={apparel.id}
                          cx={
                            apparelBox[0] + (apparelBox[2] - apparelBox[0]) / 2
                          }
                          cy={
                            apparelBox[1] + (apparelBox[3] - apparelBox[1]) / 2
                          }
                          r={16}
                          z={20}
                          fill={'#fff'}
                          onClick={() => {
                            setSelectedArea({
                              x: parseInt(apparelBox[0]),
                              y: parseInt(apparelBox[1]),
                              width: parseInt(apparelBox[2] - apparelBox[0]),
                              height: parseInt(apparelBox[3] - apparelBox[1]),
                            });
                            setSelectedApparel(null);
                          }}
                        />
                      )}
                    </>
                  );
                })}
              </svg>
            )}
            {type === 'fashionWeekItem' && (
              <Watermark className="absolute bottom-4 right-4 h-16 w-16" />
            )}
          </div>
        </div>
      )}

      {exploreLoading || fashionWeekLoading ? (
        <div className="flex max-h-full flex-col gap-8 overflow-y-auto border-l border-l-[#A1A1A1]/50 py-9 px-16">
          <div className="flex items-center justify-between">
            <h1 className="text-[2.5rem] font-semibold leading-none">
              More Ideas
            </h1>

            <RxCross1
              size={42}
              onClick={() => navigate(-2)}
              className="cursor-pointer"
            />
          </div>

          <ResponsiveMasonry
            columnsCountBreakPoints={{
              350: 2,
              1024: 3,
              1280: 3,
              1600: 4,
              1920: 4,
            }}
          >
            <Masonry gutter="1.4rem">
              {Array.from({ length: 12 }).map((_, idx) => (
                <div
                  className="h-96 w-full animate-pulse rounded-xl bg-slate-400"
                  key={idx}
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      ) : (
        <div className="flex max-h-full flex-col gap-8 overflow-y-auto border-l border-l-[#A1A1A1]/50 py-9 px-16">
          <div className="flex items-center justify-between">
            <h1 className="text-[2.5rem] font-semibold leading-none">
              More Ideas
            </h1>

            <RxCross1
              size={42}
              onClick={() => navigate(-2)}
              className="cursor-pointer"
            />
          </div>

          <ResponsiveMasonry
            columnsCountBreakPoints={{
              350: 2,
              1024: 3,
              1280: 3,
              1600: 4,
              1920: 4,
            }}
          >
            <Masonry gutter="1.4rem">
              {fashionWeekSimilarImagesData?.fashionWeekSimilarImages.data.map(
                (item) => (
                  <FashionWeekImage key={item.id} {...item} />
                ),
              )}
              {exploreData?.exploreSimilarImage.data.map((item) => (
                <SocialMediaImage key={item.id} {...item} />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      )}
    </Wrapper>
  );
}
