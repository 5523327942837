import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { capitalize } from 'lodash';

import {
  useExploreFashionItemFilters,
  useExploreFashionWeekCities,
} from '@/api/explore';
import { useFiltersQuery } from '@/api/filters/hook';
import { genderMapping, seasons } from '@/constants';
import { deepEqual } from '@/helpers/utils';
import useCurrentSection from '@/hooks/useCurrentSection';

export const AppFiltersContext = createContext();

export const getAudienceLabel = (audience) =>
  audience?.name ||
  `${genderMapping[audience.gender].label}, ${audience.ageMin}-${audience.ageMax}`;

export const getMarketLabel = (market) => market?.location?.name;

export function returnFashionItemFilters(filters) {
  let city = filters?.source?.value?.value;
  if (city === 'all') city = null;

  const season = filters?.season?.value?.value;
  const designer = filters?.designer?.value?.value;
  const category = Array.isArray(filters?.fashionWeekCategory?.value)
    ? filters?.fashionWeekCategory?.value.map((i) => ({
        value: i?.value,
        items: [],
      }))
    : filters?.fashionWeekCategory?.value?.value
      ? [
          {
            value: filters?.fashionWeekCategory?.value?.value,
            items: [],
          },
        ]
      : undefined;

  const itemType = filters?.type?.value?.value;

  return {
    city,
    season,
    designer,
    apparels: category,
    itemType,
  };
}

export const AppFiltersProvider = ({ children }) => {
  const currentSection = useCurrentSection('trends');
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const { audienceId } = useParams();

  const [filters, setFilters] = useState({
    source: {
      label: 'Market',
      placeholder: 'Loading...',
      options: [],
      value: null,
      loading: true,
    },
    platform: {
      label: 'Platform',
      placeholder: 'Select a platform',
      options: [],
      value: null,
      loading: false,
    },
    season: {
      label: 'Season',
      placeholder: 'Select a season',
      options: [],
      loading: true,
    },
    designer: {
      label: 'Designer',
      placeholder: 'Select a designer',
      options: [],
      value: null,
      loading: true,
    },
    timeframe: {
      label: 'Timeframe',
      placeholder: 'Last week',
      options: [
        { label: 'Last week', value: 'lastWeek' },
        { label: 'Last Two Weeks', value: 'lastTwoWeeks' },
        { label: 'Last Four Weeks', value: 'lastFourWeeks' },
      ],
      value: { label: 'Last week', value: 'lastWeek' },
      loading: false,
    },
    fashionWeekCategory: {
      label: 'Category',
      placeholder: 'Select a category',
      options: [],
      value: null,
      loading: true,
    },
    type: {
      label: 'Type',
      placeholder: 'Select a type',
      options: [],
      value: null,
    },
    trendsCategory: {
      label: 'Category',
      placeholder: 'Select a category',
      options: [],
      value: null,
      loading: true,
      isOpen: false,
    },
    trendsColor: {
      label: 'Color',
      placeholder: 'Select a color',
      options: [],
      value: null,
      loading: true,
      isOpen: false,
    },
    engagement: {
      label: 'Engagement',
      placeholder: 'Default',
      options: [],
      value: null,
      loading: true,
    },
    trendType: {
      label: 'Trend Type',
      placeholder: 'Default',
      options: [],
      value: null,
      loading: true,
    },
    snapshotSort: {
      label: 'Sort',
      placeholder: 'Default',
      options: [
        { label: 'Default', value: null },
        { label: 'Quality Engagement', value: 'QualityEngagement' },
        { label: 'Magnitude', value: 'Magnitude' },
        { label: 'Post Count', value: 'PostScore' },
      ],
      value: { label: 'Default', value: null },
      loading: false,
    },
  });

  const mode = useMemo(() => {
    /*
    Available modes:
    - trends
    - explore-trends
    - explore-fashion-week
     */
    return currentSection === 'explore'
      ? `explore-${filters?.source?.value?.source || 'trends'}`
      : 'trends';
  }, [filters, currentSection]);

  const selectSearchParam = (filter, filterType) => {
    const searchParam = searchParams.get(filterType);
    if (searchParam) {
      const allOptions = filter.options
        .map((option) => option?.options || option)
        .flat();

      if (searchParam.includes(',')) {
        const searchValues = searchParam.split(',');
        filter.value = allOptions.filter((option) =>
          searchValues.includes(option.value),
        ); // Assign an array of matched options
      } else {
        const option = allOptions.find(
          (option) => option.value === searchParam,
        );
        filter.value = option; // Assign a single matched option
      }
    }

    return filter;
  };

  const selectRouteParam = (filter, filterType) => {
    const routeParam = params[filterType];
    if (routeParam) {
      const allOptions = filter.options
        .map((option) => option?.options || option)
        .flat();

      if (routeParam.includes(',')) {
        const routeValues = routeParam.split(',');
        const options = allOptions.filter((option) =>
          routeValues.includes(option.value),
        );
        filter.value = options; // Assign an array of matched options
      } else {
        const option = allOptions.find((option) => option.value === routeParam);
        filter.value = option; // Assign a single matched option
      }
    }

    return filter;
  };

  const {
    data: marketsData,
    loading: marketsLoading, // error: marketsError
  } = useFiltersQuery('markets');
  const {
    data: fashionWeeksData, // loading: fashionWeeksLoading,
    // error: fashionWeeksError
  } = useExploreFashionWeekCities();
  const {
    data: fashionItemFiltersData, // loading: fashionItemFiltersLoading,
    // error: fashionItemsFilterError
  } = useExploreFashionItemFilters({
    filters: returnFashionItemFilters(filters),
  });
  const {
    data: trendFiltersData, // loading: trendFiltersLoading,
    // error: trendFiltersError
  } = useFiltersQuery('trendFilters', {
    audience: audienceId,
  });

  const sourceLabel = {
    trends: 'Market',
    explore: 'Source',
  };

  const sourcePlaceholder = {
    trends: 'Select an audience',
    explore: 'Select a market or fashion week',
  };

  const initializeSourceFilter = () => {
    const sourceOptions = [];

    if (['explore', 'trends'].includes(currentSection)) {
      const markets = marketsData?.markets;
      if (!markets) return;

      // Load market options for select dropdown
      let marketOptions = markets
        .map((market) => ({
          label: getMarketLabel(market),
          options: market?.audiences
            ?.map((audience) => ({
              label: `${getAudienceLabel(audience)}`,
              value: audience?.groups?.[0]?.audienceId,
              field: 'audience',
              source: 'trends',
              marketId: market.id,
              groupId: audience?.groupId,
              audVal: audience.id,
              location: market.location.name,
              socialWatch: audience?.groups?.[0]?.features?.socialwatch,
            }))
            .sort((a, b) => {
              if (a.socialWatch === b.socialWatch) {
                return a.label.localeCompare(b.label);
              }
              return b.socialWatch - a.socialWatch;
            }),
        }))
        .sort((a, b) => {
          const aOptions = a.options;
          const bOptions = b.options;

          const aSocialWatch = aOptions.find(
            (option) => option.socialWatch === true,
          )?.value;
          const bSocialWatch = bOptions.find(
            (option) => option.socialWatch === true,
          )?.value;

          if (a.label === filters.source.value?.location) return -1;
          if (b.label === filters.source.value?.location) return 1;

          return bSocialWatch - aSocialWatch;
        });

      if (currentSection === 'explore') {
        // Group market options for explore view
        marketOptions = [
          {
            label: 'Markets',
            options: marketOptions
              .map((market) =>
                market.options.map((audience) => ({
                  ...audience,
                  label: `${market.label}, ${audience.label}`,
                })),
              )
              .flat(),
          },
        ];

        // Add fashion week options for explore view
        const fashionWeekCities = fashionWeeksData?.exploreFashionWeekCities;
        if (fashionWeekCities) {
          const fashionWeekOptions = {
            label: 'Fashion Weeks',
            options: [
              {
                label: 'All Fashion Weeks',
                value: 'all',
                field: 'fashionWeek',
                source: 'fashion-week',
              },
              ...fashionWeekCities.map((fashionWeekCity) => ({
                label: capitalize(fashionWeekCity.replaceAll('-', ' ')),
                value: fashionWeekCity,
                field: 'fashionWeek',
                source: 'fashion-week',
              })),
            ],
          };

          sourceOptions.push(fashionWeekOptions);
        }
      }

      sourceOptions.push(marketOptions);
    }

    const sourceFilter = {
      ...filters.source,
      label: sourceLabel[currentSection],
      placeholder: sourcePlaceholder[currentSection],
      loading: marketsLoading,
      options: sourceOptions.flat(),
    };

    selectSearchParam(sourceFilter, 'source');

    // if (params.marketId) {
    //   const marketOption = sourceFilter.options
    //     .map((i) => i?.options || i)
    //     .flat()
    //     .find((option) => option.marketId === params.marketId);
    //   if (marketOption) {
    //     sourceFilter.value = marketOption;
    //   }
    // }

    // if (params.audienceId) {
    //   const audienceOption = sourceFilter.options
    //     .map((i) => i?.options || i)
    //     .flat()
    //     .find((option) => option.value === params.audienceId);
    //   if (audienceOption) {
    //     sourceFilter.value = audienceOption;
    //   }
    // }

    const getMarket = sourceFilter.options.find((option) =>
      option.options.find((audience) => audience.marketId === params.marketId),
    );

    const getAudience = getMarket?.options.find(
      (audience) => audience.value === params.audienceId,
    );

    sourceFilter.value = getAudience;

    const market = marketsData?.markets.find(
      (market) => market.id === getAudience?.marketId,
    );

    const audience = market?.audiences.find((audience) => {
      const key = audience.id;

      return key === getAudience?.audVal;
    });

    if (audience) {
      filters.platform = {
        ...filters.platform,
        options: audience.groups.map((platform) => ({
          label: platform.platform,
          value: platform.platform,
          audienceId: platform.audienceId,
        })),
        value: {
          label: audience.groups[0].platform,
          value: audience.groups[0].platform,
        },
      };
    }

    return sourceFilter;
  };

  const initializeSeasonFilter = () => {
    let seasonOptions = [];

    const isFashionWeek = mode === 'explore-fashion-week';
    if (!isFashionWeek) {
      seasonOptions = seasons;

      const seasonFilter = {
        ...filters.season,
        options: seasonOptions,
        value: mode === 'trends' ? seasonOptions[0] : null,
        loading: false,
      };

      selectSearchParam(seasonFilter, 'season');
      selectRouteParam(seasonFilter, 'seasonId');

      return seasonFilter;
    }
  };

  const initializeFashionItemFilters = () => {
    if (currentSection === 'explore' && mode === 'explore-fashion-week') {
      const fashionWeekItemFilters = {
        designer: {},
        fashionWeekCategory: {},
        type: {},
        season: {},
      };

      const filterData =
        fashionItemFiltersData?.exploreFashionWeekItemFilters || [];

      for (const filter of filterData) {
        let filterKey = filter.label;
        if (filterKey === 'category') filterKey = 'fashionWeekCategory';

        if (!fashionWeekItemFilters[filterKey]) continue;
        if (filters[filterKey]?.value) continue;

        fashionWeekItemFilters[filterKey] = {
          label: capitalize(filter.label),
          placeholder: 'Select a ' + filter.label,
          options: filter.values
            .map((item) => ({
              ...item,
              label: capitalize(item.label.replaceAll(/[-_]/g, ' ')),
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        };

        if (filterKey === 'season') {
          fashionWeekItemFilters[filterKey].value = null;
        }

        selectSearchParam(fashionWeekItemFilters[filterKey], filterKey);
      }

      return fashionWeekItemFilters;
    }

    return {};
  };

  const initializeTrendFilters = () => {
    const trendFilters = {
      trendCategory: {},
      trendsColor: {},
      trendPattern: {},
    };

    const filterData = trendFiltersData?.trendFilters;

    if (!filterData) return trendFilters;

    for (const filter in filterData) {
      const filterOptions = filterData[filter];
      const filterMappings = {
        apparels: {
          label: 'Category',
          fieldName: 'trendsCategory',
        },
        colors: {
          label: 'Color',
          fieldName: 'trendsColor',
        },
        patterns: {
          label: 'Pattern',
          fieldName: 'trendsPattern',
        },
      };

      const filterMapping = filterMappings[filter];
      if (!filterOptions || !filterMapping) continue;

      trendFilters[filterMapping.fieldName] = {
        ...filters[filterMapping.fieldName],
        label: capitalize(filterMapping.label),
        placeholder: 'Select a ' + filterMapping.label.toLowerCase(),
        options: filterOptions?.map((item) => ({
          ...item,
          originalLabel: item.label,
          value: item?.value ? item?.value : item?.label,
          label: capitalize(item.label.replaceAll('-', ' ')),
        })),
      };
    }

    return trendFilters;
  };

  const initializeSortingFilters = () => {
    const sortingFilters = {
      engagement: {},
      trendType: {},
    };

    sortingFilters.engagement = {
      ...filters.engagement,
      label: 'Engagement',
      placeholder: 'Default',
      options: [
        {
          label: 'Ascending',
          field: 'engagement',
          value: 'asc',
        },
        {
          label: 'Descending',
          field: 'engagement',
          value: 'desc',
        },
      ],
    };

    sortingFilters.trendType = {
      ...filters.trendType,
      label: 'Trend Type',
      placeholder: 'Default',
      options: [
        {
          label: 'Ascending',
          field: 'trendType',
          value: 'asc',
        },
        {
          label: 'Descending',
          field: 'trendType',
          value: 'desc',
        },
      ],
    };

    return sortingFilters;
  };

  const initializeFilters = useCallback(() => {
    if (!currentSection) return;

    const updatedFilters = {};

    const sourceFilter = initializeSourceFilter();
    if (sourceFilter?.label) updatedFilters.source = sourceFilter;

    const seasonFilter = initializeSeasonFilter();

    if (seasonFilter?.label) updatedFilters.season = seasonFilter;

    const { designer, fashionWeekCategory, type, season } =
      initializeFashionItemFilters();

    if (designer?.label) updatedFilters.designer = designer;

    if (fashionWeekCategory?.label) {
      updatedFilters.fashionWeekCategory = fashionWeekCategory;
    }

    if (season?.label) updatedFilters.season = season;
    if (type?.label) updatedFilters.type = type;

    const { trendsCategory, trendsColor, trendsPatterns } =
      initializeTrendFilters();
    if (trendsCategory?.label) updatedFilters.trendsCategory = trendsCategory;
    if (trendsColor?.label) updatedFilters.trendsColor = trendsColor;
    if (trendsPatterns?.label) updatedFilters.trendsPatterns = trendsPatterns;

    const { engagement, trendType } = initializeSortingFilters();
    if (engagement?.label) updatedFilters.engagement = engagement;
    if (trendType?.label) updatedFilters.trendType = trendType;

    const newFilters = {
      ...filters,
      ...updatedFilters,
    };

    if (deepEqual(newFilters, filters)) return;

    setFilters(newFilters);
  }, [
    currentSection,
    filters,
    initializeSourceFilter,
    initializeSeasonFilter,
    initializeFashionItemFilters,
    initializeTrendFilters,
  ]);

  useEffect(() => {
    initializeFilters();
  }, []);

  useEffect(() => {
    initializeFilters();
  }, [
    currentSection,
    marketsData,
    fashionWeeksData,
    fashionItemFiltersData,
    trendFiltersData,
    mode,
  ]);

  const navigate = useNavigate();

  const { tab } = useParams();

  const handleOnChange = (item, filter) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);

      if (filter === 'snapshotSort') {
        if (!item?.value) {
          newParams.delete(filter);

          return newParams;
        }

        newParams.set(filter, item?.value);
        return newParams;
      }

      if (Array.isArray(item)) {
        const values = item.map((item) => item?.value).filter(Boolean);
        if (values.length) {
          newParams.set(filter, values.join(','));
        } else {
          newParams.delete(filter);
        }
        return newParams;
      }

      if (!item?.value || filter === 'source' || filter === 'platform') {
        newParams.delete(filter);
      } else {
        newParams.set(filter, item?.value);
      }
      return newParams;
    });

    if (filter === 'source') {
      const market = marketsData?.markets.find(
        (market) => market.id === item?.marketId,
      );

      const audience = market?.audiences.find((audience) => {
        const key = audience.id;

        return key === item?.audVal;
      });
      if (audience) {
        const timeframe = searchParams.get('timeframe');
        const timeframeParam = timeframe ? `?timeframe=${timeframe}` : '';
        navigate(
          `/trends/${tab}/${item?.marketId}/${item?.groupId}${timeframeParam}`,
        );
      }
    }

    if (filter === 'platform') {
      const timeframe = searchParams.get('timeframe');
      const timeframeParam = timeframe ? `?timeframe=${timeframe}` : '';
      navigate(
        `/trends/${tab}/${params.marketId}/${item?.audienceId}${timeframeParam}`,
      );
    }

    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        [filter]: {
          ...prevFilters[filter],
          value:
            filter === 'source'
              ? { ...item, label: `${item.location}, ${item.label}` }
              : item,
        },
      };
    });
  };

  const handleTrendsClearAll = () => {
    const fields = ['trendsCategory', 'trendsColor', 'trendsPattern'];

    const newFilters = {};
    for (const field of fields) {
      newFilters[field] = {
        ...filters[field],
        value: null,
      };
    }

    setFilters({
      ...filters,
      ...newFilters,
    });
  };

  const handleSourceOnChange = (item) => handleOnChange(item, 'source');
  const handlePlatformOnChange = (item) => handleOnChange(item, 'platform');
  const handleSeasonOnChange = (item) => handleOnChange(item, 'season');
  const handleTimeframeOnChange = (item) => handleOnChange(item, 'timeframe');
  const handleTrendsColorsOnChange = (item) =>
    handleOnChange(item, 'trendsColor');
  const handleDesignerOnChange = (item) => handleOnChange(item, 'designer');
  const handleTypeOnChange = (item) => handleOnChange(item, 'type');
  const handleFashionWeekCategoryOnChange = (item) =>
    handleOnChange(item, 'fashionWeekCategory');
  const handleTrendsCategoryOnChange = (item) =>
    handleOnChange(item, 'trendsCategory');
  const handleTrendsColorOnChange = (item) =>
    handleOnChange(item, 'trendsColor');
  const handleEngagementOnChange = (item) => handleOnChange(item, 'engagement');
  const handleTrendTypeOnChange = (item) => handleOnChange(item, 'trendType');
  const handleSnapshotSortOnChange = (item) =>
    handleOnChange(item, 'snapshotSort');

  const toggleFilter = (filterName) => {
    const isOpen = filters[filterName]?.isOpen;

    setFilters((prev) => {
      return {
        ...prev,
        [filterName]: {
          ...prev[filterName],
          isOpen: !isOpen,
        },
      };
    });
  };

  const openFilter = (filterName) => {
    setFilters((prev) => {
      return {
        ...prev,
        [filterName]: {
          ...prev[filterName],
          isOpen: true,
        },
      };
    });
  };

  const closeFilter = (filterName) => {
    setFilters((prev) => {
      return {
        ...prev,
        [filterName]: {
          ...prev[filterName],
          isOpen: false,
        },
      };
    });
  };

  const selectedTrendsColors = filters?.trendsColor?.value;
  const selectedTrendsCategory = filters?.trendsCategory?.value;
  const selectedPatterns = filters?.trendsPattern?.value;
  const selectedSeason = filters?.season?.value;
  const selectedTimeframe = searchParams.has('timeframe')
    ? searchParams.get('timeframe')
    : filters?.timeframe?.value.value;
  const selectedAudience = filters?.source?.value;
  const selectedSource = filters?.source?.value;
  const selectedSorting =
    filters?.engagement?.value || filters?.trendType?.value;
  const selectedSnapshotSort =
    filters?.snapshotSort?.value?.value || searchParams.get('snapshotSort');

  return (
    <AppFiltersContext.Provider
      value={{
        filters,
        mode,
        handleSourceOnChange,
        handlePlatformOnChange,
        handleSeasonOnChange,
        handleTimeframeOnChange,
        handleDesignerOnChange,
        handleTypeOnChange,
        handleFashionWeekCategoryOnChange,
        handleTrendsCategoryOnChange,
        handleEngagementOnChange,
        handleTrendTypeOnChange,
        handleTrendsColorOnChange,
        handleTrendsClearAll,
        handleTrendsColorsOnChange,
        toggleFilter,
        openFilter,
        closeFilter,

        handleSnapshotSortOnChange,
        selectedSnapshotSort,

        selectedTrendsColors,
        selectedPatterns,
        selectedTrendsCategory,
        selectedSeason,
        selectedTimeframe,
        selectedAudience,
        selectedSource,
        selectedSorting,
      }}
    >
      {children}
    </AppFiltersContext.Provider>
  );
};

export const useAppFilters = () => useContext(AppFiltersContext);
