/* eslint-disable prettier/prettier */

import { gql } from '@apollo/client';

export const STUDIO_TASK = gql`
  query studioTask($id: ID!) {
    studioTask(id: $id) {
      id
      seed
      images {
        url
        width
        height
        blurhash
        studioItemId
      }
      organization {
        id
        name
      }
      createdBy {
        id
        fullName
      }
      visibility
      status
      payload
      createdAt
      generateTaskType
    }
  }
`;


export const GET_STUDIO_TASK_HISTORY = gql`
  query studioTaskHistory($cursor: String, $generationType: STUDIO_TASK_TYPE) {
  studioTaskHistory(cursor: $cursor, generationType: $generationType) {
      edges {
        node {
          id
          seed
          images {
            url
            width
            height
            blurhash
            studioItemId
          }
          createdBy {
            id
            fullName
          }
          payload
          visibility
          status
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GET_SIMILAR_IMAGES = gql`
  query studioSimilarImages($studioSimilarImagesId: ID!) {
    studioSimilarImages(id: $studioSimilarImagesId) {
      node {
        id
        seed
        image {
          url
          width
          height
          blurhash
          studioItemId
        }
        images {
          url
          blurhash
          height
          width
        }
        createdBy {
          id
          fullName
        }
        payload
        createdAt
      }
    }
  }
`;

export const GET_INSPIRED_IMAGES = gql`
  query getInspiredImages($cursor: String) {
    getInspiredImages(cursor: $cursor) {
      edges {
        node {
          id
          seed
          image {
            url
            width
            height
            blurhash
            studioItemId
          }
          createdBy {
            id
            fullName
          }
          payload
          visibility
          status
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GET_STUDIO_ITEM = gql`
  query studioItem($id: String) {
    studioItem(id: $id) {
      node {
        id
        image {
          url
          width
          height
          blurhash
        }
        createdBy {
          id
          fullName
        }
        organization {
          name
        }
        payload
        visibility
        mode
        status
        createdAt
        generateTaskType
      }
    }
  }
`;

export const GENERATE_FILE_UPLOAD_URL = gql`
  query GenerateFileUploadUrl(
    $fileVisibility: FILE_VISIBILITY!
    $fileName: String!
    $mimeType: String!
    $fileEntityType: FILE_ENTITY_TYPE!
  ) {
    generateFileUploadUrl(
      fileVisibility: $fileVisibility
      fileName: $fileName
      mimeType: $mimeType
      fileEntityType: $fileEntityType
    ) {
      signedUrl
      entityId
    }
  }
`;

export const GET_STUDIO_EXPLORE = gql`
query StudioExplore($cursor: String, $entityType: EntityType, $generationType: STUDIO_TASK_TYPE!) {
   studioExplore(cursor: $cursor, entityType: $entityType, generationType: $generationType) {
      edges {
        node {
          id
          visibility
          image {
            url
            width
            height
            blurhash
          }
          images {
            blurhash
            height
            url
            width
            studioItemId
          }
          organization {
            id
            name
          }
          createdAt
        }
        entityType
      }
      pageInfo {
        hasNextPage
        endCursor
        total
      }
    }
  }
`;

export const FASHION_WEEK_ITEM = gql`
  query FashionWeekItem($itemId: String) {
    fashionWeekItem(itemId: $itemId) {
      photo {
        image {
          url
          width
          height
          urlKey
          blurhash
        }
        designer
        season
        city
        id
      }
      apparels {
        originalBox
        box
        tones {
          hex
          main
          code
          name
          weight
        }
        attributes {
          name
          value
        }
        apparel
        id
      }
    }
  }
`;

export const SOCIAL_MEDIA_ITEM = gql`
  query Post($postId: String) {
    post(id: $postId) {
      image {
        url
        width
        height
      }
      id
    }
  }
`;

export const UPLOAD_ITEM_IMAGE = gql`
  query Node($uploadItemImageId: ID!) {
    uploadItemImage(id: $uploadItemImageId) {
      node {
        id
        image {
          url
          width
          height
        }
        fileEntityType
      }
    }
  }
`;

export const GENERATE_NEW_PROMPT_FROM_IMAGE = gql`
 query StudioGenerateNewPromptFromImage($payload: ImageFromImageGenerationInput!, $generationType: STUDIO_TASK_TYPE!) {
  studioGenerateNewPromptFromImage(payload: $payload, generationType: $generationType) {
    prompt
  }
}
`;