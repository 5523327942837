import { useEffect, useMemo, useState } from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import { debounce } from 'lodash';

import { ME } from '@/api/authentication';
import { useDemoAccount } from '@/api/authentication/hook';
import { EXPLORE } from '@/api/explore';
import { FlexRow, LikeButton } from '@/components';
import { DemoAccountModal } from '@/components/DemoAccountPopup';
import { useExploreContext } from '@/contexts/ExploreContext';
import { SaveToCollection } from '@/layouts/common/saveToCollection';

import {
  ExploreLoadingScreen,
  LazyLoadTrigger,
  NoSuchResultsWereFound,
} from './components/utils';
import { Card } from './components';

export const SocialMediaImages = () => {
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [cursorNext, setCursorNext] = useState(null);

  const { data: meData } = useQuery(ME);

  const [showDemoPopup, setShowDemoPopup] = useState(false);

  const { isDemoAccount } = useDemoAccount();

  const {
    hasBannedApparel,
    setHasBannedApparel,
    shouldRefetch,
    setShouldRefetch,
    socialMediaSorting,
  } = useExploreContext();

  const moderationEnabled =
    meData?.me?.role === 'admin' || meData?.me?.role === 'moderator';

  const {
    selectedSocialMediaColor: selectedTrendsColors,
    selectedSocialMediaCategory: selectedTrendsCategory,
    selectedSocialMediaTimeframe: selectedSeason,
    selectedSocialMediaAudience: selectedAudience,
    selectedSocialMediaTone: selectedTone,
    socialMediaFilters,
    selectedSocialMediaPersonCount: selectedPersonCount,
    selectedSocialMediaPersonPosition: selectedPersonPosition,
    socialMediaSorting: selectedSorting,
    setPosts,
    marketsData,
  } = useExploreContext();

  const selectedAudienceId = selectedAudience;
  const selectedMarketId = selectedAudience?.marketId;

  // Memoize filters to avoid unnecessary recalculations
  const memoizedApparelFilter = useMemo(() => {
    return selectedTrendsCategory
      ? selectedTrendsCategory.map((category) => ({
          value: category.value,
        }))
      : null;
  }, [selectedTrendsCategory]);
  const memoizedColorFilter = useMemo(() => {
    return selectedTrendsColors ? [selectedTrendsColors.value] : null;
  }, [selectedTrendsColors]);

  const variables = {
    cursor: null,
    filters: {
      apparels: memoizedApparelFilter,
      colors: memoizedColorFilter,
      patterns: null, // patternFilter,
      season: selectedSeason?.label?.toLowerCase() || null,
      tone: null,
      attributes: null,
      // selectedTone
      // ? {
      //     code: selectedTone.value,
      //     similarity: "1"
      //   }
      // : null
      personCount: null,
      personPosition: null,
    },
    audiences:
      selectedAudienceId && typeof selectedAudienceId === 'string'
        ? [selectedAudienceId]
        : selectedAudienceId?.[0] || [],
    market: selectedMarketId
      ? !['demo_market_1', 'demo_market_2'].includes(selectedMarketId)
        ? selectedMarketId
        : null
      : null,
    platform: socialMediaFilters.platform.value?.value,
    sorting:
      !socialMediaSorting || !socialMediaSorting?.value
        ? null
        : {
            sortBy: socialMediaSorting.value.split('.')[0],
            sortOrder: socialMediaSorting.value.split('.')[1],
          },
    searchQuery: socialMediaFilters.search?.value,
  };

  const [prevVariables, setPrevVariables] = useState(JSON.stringify(variables));

  const { data, networkStatus, error, refetch, fetchMore } = useQuery(EXPLORE, {
    variables,
    onCompleted: (newData) => {
      setPosts(
        newData?.explore?.data.map((d) => ({
          ...d,
          post: {
            ...d.post,
            apparels: d.post.apparels.map((a, i) => ({
              ...a,
              apparel: i === 0 ? d.title : a.apparel,
            })),
          },
        })),
      );
      if (newData?.explore?.cursor === null) {
        setFullyLoaded(true);
      } else {
        setFullyLoaded(false);
        setCursorNext(newData.explore.cursor);
      }
      hasBannedApparel === true && setHasBannedApparel(false);
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useMemo(() => debounce(refetch, 300), [refetch]);

  useEffect(() => {
    if (socialMediaSorting) {
      debouncedRefetch({
        sorting: {
          sortBy: socialMediaSorting?.value?.split('.')[0],
          sortOrder: selectedSorting?.value?.split('.')[1],
        },
      });
    } else {
      debouncedRefetch();
    }
  }, [socialMediaSorting]);

  useEffect(() => {
    if (hasBannedApparel) {
      debouncedRefetch();
    }
  }, [hasBannedApparel]);

  useEffect(() => {
    debouncedRefetch();
  }, [socialMediaFilters.search.value]);

  useEffect(() => {
    const attributes = Object.entries(socialMediaFilters)
      .map(([key, val]) => {
        if (key === 'attribute.custom') {
          return val?.value?.map((v) => {
            return {
              name: v?.name,
              value: v?.value,
            };
          });
        }

        return false;
      })
      .filter(Boolean);

    const allAudiencesOfSelectedGender = marketsData?.markets
      ?.flatMap((market) => market.audiences)
      .filter(
        (audience) =>
          audience.gender.toString() ===
          socialMediaFilters.gender.value.value.toString(),
      )
      .flatMap((audience) => audience.groups)
      .map((group) => group.audienceId);

    const allAudiences = marketsData?.markets
      ?.flatMap((market) => market.audiences)
      .flatMap((audience) => audience.groups)
      .map((group) => ({
        audienceId: group.audienceId,
        platform: group.platform,
      }));

    const newVariables = {
      cursor: null,
      filters: {
        apparels: memoizedApparelFilter,
        colors: memoizedColorFilter,
        patterns: null,
        season: selectedSeason?.label?.toLowerCase() || null,
        tone:
          selectedTone !== null
            ? {
                code: selectedTone?.code,
                similarity: 3,
              }
            : null,
        attributes:
          attributes.length > 0
            ? attributes[0].length === 0
              ? null
              : attributes[0]
            : null,
        personCount: selectedPersonCount?.value,
        personPosition: selectedPersonPosition?.value,
      },
      audiences: (Array.isArray(selectedAudienceId) &&
      selectedAudienceId.length === 0
        ? allAudiencesOfSelectedGender
        : selectedAudienceId || []
      )?.filter((id) => {
        if (selectedAudienceId.length === 0) {
          return true;
        }

        if (socialMediaFilters.platform.value?.value === 'ALL') {
          return true;
        }

        const audiencePlatform = allAudiences?.find(
          ({ audienceId }) => audienceId === id,
        )?.platform;

        if (socialMediaFilters.platform.value?.value === undefined) {
          return true;
        }

        return (
          id && audiencePlatform === socialMediaFilters.platform.value?.value
        );
      }),
      market: selectedMarketId
        ? !['demo_market_1', 'demo_market_2'].includes(selectedMarketId)
          ? selectedMarketId
          : null
        : null,
      platform: socialMediaFilters.platform.value?.value || 'ALL',
      searchQuery: socialMediaFilters.search.value,
    };

    // if (selectedSorting?.value) {
    //   const [sortBy, sortOrder] = selectedSorting.value.split('.');
    //   newVariables.sorting = {
    //     sortBy,
    //     sortOrder,
    //   };
    // }

    if (shouldRefetch) {
      refetch(newVariables);
      setShouldRefetch(false);
    }

    if (JSON.stringify(newVariables) !== prevVariables) {
      setPrevVariables(JSON.stringify(newVariables));
    }
  }, [
    shouldRefetch,
    selectedAudienceId,
    selectedMarketId,
    selectedSeason,
    memoizedApparelFilter,
    memoizedColorFilter,
    socialMediaFilters,
    prevVariables,
    debouncedRefetch,
    selectedPersonCount,
    selectedPersonPosition,
    selectedTone,
    refetch,
    setShouldRefetch,
  ]);

  if (
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.refetch ||
    !data?.explore
  ) {
    return <ExploreLoadingScreen />;
  }

  if (error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p>{error.message}</p>
      </div>
    );
  }

  const renderLazyLoad =
    networkStatus !== NetworkStatus.fetchMore &&
    data.explore.hasNextPage &&
    fullyLoaded === false;

  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <>
      <DemoAccountModal
        isOpen={showDemoPopup}
        onClose={() => setShowDemoPopup(false)}
      />
      <div>
        {data?.explore?.data?.length === 0 && <NoSuchResultsWereFound />}

        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
          {data?.explore?.data?.map((exploreItem, index) => (
            <div key={'explore_' + index} className="group">
              <Card.Root
                data={exploreItem}
                moderationEnabled={moderationEnabled}
                isCarousel={exploreItem.post?.children?.length > 1}
              >
                <Card.Image />

                <Card.HoverContent>
                  <FlexRow justify="between" items="center">
                    <Card.Tones />

                    <FlexRow
                      className="self-end"
                      justify="between"
                      items="center"
                      gap="sm"
                    >
                      <SaveToCollection
                        id={exploreItem?.post?.id}
                        collections={exploreItem?.collections}
                        type="post"
                        variant="explore"
                      />

                      {/* <More /> */}

                      <LikeButton
                        item={exploreItem?.post?.id}
                        isLiked={exploreItem?.isLiked}
                        type="post"
                      />
                    </FlexRow>
                  </FlexRow>
                  <Card.Footer
                    id={exploreItem.post?.id}
                    exploreItemId={exploreItem?.id}
                    post={exploreItem?.post}
                    demoPopupClick={
                      isDemoAccount ? () => setShowDemoPopup(true) : null
                    }
                  />
                </Card.HoverContent>
              </Card.Root>
            </div>
          ))}
          {isLoadingMore &&
            Array.from({ length: 20 }).map((_, i) => (
              <div className="ghost aspect-[3/5] w-full" key={i}></div>
            ))}
        </div>
        {renderLazyLoad && (
          <div className="mb-8 h-16 w-full bg-transparent">
            <LazyLoadTrigger
              fetchMore={fetchMore}
              loading={networkStatus === NetworkStatus.loading}
              cursorNext={cursorNext}
            />
          </div>
        )}
      </div>
    </>
  );
};
