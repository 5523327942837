import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { X } from 'lucide-react';

import { ImageSlider } from '@/components/ImageSlider/index';
import { STUDIO_DISPLAY, STUDIO_TOOLS_CONFIG } from '@/constants/studio';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';
import { SaveToCollection } from '@/layouts/common/index';

import { GenerateButton } from './components/GenerateButton';
import { SidebarButton } from './components/SidebarMenu';
import { ImagePreview, TileImage } from './components/StudioContents';
import StudioImage from './components/StudioImage';
import {
  GenerationElapsed,
  SettingsButtonGroup,
} from './components/StudioUtils';

const useImageDisplay = (generatedImages, orientation) => {
  const {
    selectedGeneratedImage,
    setSelectedGeneratedImage,
    displayMode,
    setDisplayMode,
    setOpenTools,
    studioTask,
    setReferenceImage,
    setReferencePattern,
    updateCreateItemDetails,
    openTools,
  } = useStudioContext();

  const determineDisplayMode = useCallback(() => {
    if (studioTask?.images?.length > 0) {
      if (studioTask?.images?.length === 4) {
        setDisplayMode(STUDIO_DISPLAY.RESULT.TILE);
      } else {
        setDisplayMode(STUDIO_DISPLAY.PREVIEW);
        setOpenTools(true);
      }
    }
  }, [studioTask, setDisplayMode, setOpenTools]);

  const handleImageClick = useCallback(
    (index) => {
      setSelectedGeneratedImage(index);
      setDisplayMode(STUDIO_DISPLAY.RESULT.SOLO);
    },
    [setSelectedGeneratedImage, setDisplayMode],
  );

  const closePreview = useCallback(() => {
    setDisplayMode(STUDIO_DISPLAY.RESULT.TILE);
    setReferenceImage(null);
    setReferencePattern(null);
    setSelectedGeneratedImage(-1);
    updateCreateItemDetails(null);
    if (openTools) setOpenTools(false);
  }, [
    setDisplayMode,
    setReferenceImage,
    setReferencePattern,
    setSelectedGeneratedImage,
    updateCreateItemDetails,
    openTools,
    setOpenTools,
  ]);

  useEffect(() => {
    determineDisplayMode();
  }, [studioTask?.images, studioTask?.status, determineDisplayMode]);

  return {
    displayMode,
    selectedGeneratedImage,
    handleImageClick,
    closePreview,
    studioTask,
  };
};

const ImageHeader = ({
  taskStatus,
  generatedImages,
  selectedGeneratedImage,
  closePreview,
  downloadFile,
  studioTask,
}) => {
  const location = useLocation();

  const { createItem } = useStudioContext();

  if (
    [
      'PREPARING',
      'PENDING',
      'GENERATING',
      'READY_FOR_GENERATION',
      'PENDING_GENERATION',
    ].includes(taskStatus)
  ) {
    return (
      <h2 className="pt-2 text-2xl font-bold leading-6 text-primary">
        {STUDIO_TOOLS_CONFIG[createItem?.type]?.loading ||
          STUDIO_TOOLS_CONFIG[studioTask?.generateTaskType]?.loading ||
          'Generating'}{' '}
        <GenerationElapsed />
      </h2>
    );
  }

  if (
    taskStatus === 'COMPLETED' &&
    selectedGeneratedImage !== -1 &&
    generatedImages.length > 1
  ) {
    return (
      <PreviewHeader
        selectedImage={generatedImages[selectedGeneratedImage]}
        onClose={closePreview}
        downloadFile={downloadFile}
        taskStatus={taskStatus}
      />
    );
  }

  if (
    location.search.includes('generationType') ||
    generatedImages.length === 1
  ) {
    return (
      <PreviewHeader
        selectedImage={generatedImages[selectedGeneratedImage]}
        downloadFile={downloadFile}
        taskStatus={taskStatus}
        isSoloPreview={true}
        location={location}
      />
    );
  }

  if (taskStatus === 'FAILED') {
    return (
      <h2 className="pt-2 text-2xl font-bold leading-6 text-primary">FAILED</h2>
    );
  }

  if (taskStatus === 'LOADING') {
    return (
      <h2 className="pt-2 text-2xl font-bold leading-6 text-primary">
        Loading
      </h2>
    );
  }

  return (
    <div className="flex items-center justify-between">
      <h2 className="pt-2 text-2xl font-bold leading-6 text-primary">Result</h2>
    </div>
  );
};

const ImageContent = ({
  displayMode,
  studioTask,
  generatedImages,
  orientation,
  selectedGeneratedImage,
  handleImageClick,
}) => {
  const {
    selectedTool,
    setSelectedArea,
    setReferenceImage,
    setReferenceGraphic,
    setSelectedBrushPathArray,
    createItem,
    selectedCompareImage,
    setReferencePattern,
    setReferenceSketch,
  } = useStudioContext();

  const debouncedSetSelectedArea = useMemo(
    () => debounce(setSelectedArea, 100),
    [setSelectedArea],
  );

  const oldImage = { ...studioTask?.payload?.image };
  oldImage.height = generatedImages[0]?.height;
  oldImage.width = generatedImages[0]?.width;

  if (
    [
      'LOADING',
      'PENDING',
      'GENERATING',
      'READY_FOR_GENERATION',
      'PENDING_GENERATION',
    ].includes(studioTask?.status)
  ) {
    return <GeneratingPlaceholder />;
  }
  if (
    studioTask?.status === 'COMPLETED' &&
    displayMode === STUDIO_DISPLAY.RESULT.TILE
  ) {
    return (
      <TileView
        images={generatedImages}
        orientation={orientation}
        onImageClick={handleImageClick}
      />
    );
  }
  if (
    studioTask?.status === 'COMPLETED' &&
    displayMode === STUDIO_DISPLAY.RESULT.SOLO
  ) {
    return (
      <ImagePreview
        images={generatedImages}
        activeIndex={selectedGeneratedImage}
        setActiveIndex={handleImageClick}
      />
    );
  }
  if (
    studioTask?.status === 'COMPLETED' &&
    displayMode === STUDIO_DISPLAY.PREVIEW &&
    selectedCompareImage === 'slider' &&
    selectedTool === '' &&
    !location.search.includes('generationType') &&
    studioTask?.generateTaskType !== 'UPSCALE_IMAGE' &&
    (createItem?.hasCompareResult ||
      STUDIO_TOOLS_CONFIG[studioTask?.generateTaskType]?.hasCompareResult)
  ) {
    return (
      <ImageSlider
        firstImage={generatedImages[0].url}
        secondImage={oldImage.url}
        variant="studio"
      />
    );
  }
  if (
    studioTask?.status === 'COMPLETED' &&
    displayMode === STUDIO_DISPLAY.PREVIEW
  ) {
    return generatedImages.length > 0 ? (
      <StudioImage
        image={selectedCompareImage === 'old' ? oldImage : generatedImages[0]}
        actionType={createItem?.canUseEditTools?.[0] || 'default'}
        onChangeSelectedArea={(box) => {
          debouncedSetSelectedArea(box);
          if (selectedTool === 'zoomOut') {
            debouncedSetSelectedArea({
              x: 0,
              y: 0,
              width: generatedImages[0].height * 0.2,
              height: generatedImages[0].width * 0.2,
            });
          }
        }}
        onClickHook={() => {
          if (
            [
              'GENERATE_PATTERN',
              'CREATE_PATTERN_VARIANTS',
              'PATTERN_TILE',
            ].includes(
              (selectedCompareImage === 'old'
                ? oldImage.generateTaskType
                : generatedImages[0].generateTaskType) ||
                studioTask?.generateTaskType,
            )
          ) {
            setReferencePattern({
              entityType: generatedImages[0].entityType || 'STUDIO_ITEM',
              entityId:
                generatedImages[0].entityId || generatedImages[0].studioItemId,
              url: generatedImages[0].url,
            });
          } else if (
            ['GENERATE_FLAT_SKETCH', 'REALIZE_SKETCH'].includes(
              generatedImages[0].generateTaskType ||
                studioTask?.generateTaskType,
            )
          ) {
            setReferenceImage({
              entityType:
                (selectedCompareImage === 'old'
                  ? oldImage.entityType
                  : generatedImages[0].entityType) || 'STUDIO_ITEM',
              entityId:
                (selectedCompareImage === 'old'
                  ? oldImage.entityId
                  : generatedImages[0].entityId) ||
                generatedImages[0].studioItemId,
              url:
                selectedCompareImage === 'old'
                  ? oldImage.url
                  : generatedImages[0].url,
            });
            setReferenceSketch({
              entityType: generatedImages[0].entityType || 'STUDIO_ITEM',
              entityId:
                generatedImages[0].entityId || generatedImages[0].studioItemId,
              url: generatedImages[0].url,
            });
          } else {
            setReferenceImage({
              entityType:
                (selectedCompareImage === 'old'
                  ? oldImage.entityType
                  : generatedImages[0].entityType) || 'STUDIO_ITEM',
              entityId:
                (selectedCompareImage === 'old'
                  ? oldImage.entityId
                  : generatedImages[0].entityId) ||
                generatedImages[0].studioItemId,
              url:
                selectedCompareImage === 'old'
                  ? oldImage.url
                  : generatedImages[0].url,
            });
            setReferenceGraphic({
              entityType: generatedImages[0].entityType || 'STUDIO_ITEM',
              entityId:
                generatedImages[0].entityId || generatedImages[0].studioItemId,
              url: generatedImages[0].url,
            });
          }
        }}
        onChangeBrush={(arr) => {
          setSelectedBrushPathArray(arr);
        }}
      />
    ) : (
      <GeneratingPlaceholder />
    );
  }
  return <GeneratingPlaceholder />;
};

const GeneratingPlaceholder = () => {
  const { orientation, studioTask } = useStudioContext();

  const hasFourResult =
    STUDIO_TOOLS_CONFIG[studioTask?.generateTaskType]?.pendingAreaBoxCount ===
    4;

  return (
    <div className="flex w-full max-w-[50rem] flex-col gap-6 rounded-lg">
      <div
        className={cn('grid grid-rows-1 grid-cols-1 gap-6', {
          'grid-cols-2 grid-rows-2': hasFourResult,
          'aspect-[3/4]': orientation === 'PORTRAIT',
          'aspect-square': orientation === 'SQUARE',
          'aspect-[4/3]': orientation === 'LANDSCAPE',
        })}
      >
        {Array.from({ length: hasFourResult ? 4 : 1 }).map((_, index) => (
          <div
            key={index}
            className={cn('ghost rounded', {
              'aspect-[3/4]': orientation === 'PORTRAIT',
              'aspect-square': orientation === 'SQUARE',
              'aspect-[4/3]': orientation === 'LANDSCAPE',
            })}
          ></div>
        ))}
      </div>
    </div>
  );
};

const TileView = ({ images, orientation, onImageClick }) => {
  const formattedStr = orientation?.value?.toLowerCase();
  const aspect =
    formattedStr === 'square'
      ? '[1/1]'
      : formattedStr === 'portrait'
        ? '[3/4]'
        : '[4/3]';
  return (
    <div className="grid grid-cols-2 grid-rows-2 gap-6">
      {images.map((image, index) => (
        <TileImage
          key={index}
          imageUrl={image.url}
          itemId={image.studioItemId}
          aspect={aspect}
          alt={image.studioItemId}
          onClick={() => onImageClick(index)}
        />
      ))}
    </div>
  );
};

const PreviewHeader = ({
  selectedImage,
  onClose,
  downloadFile,
  taskStatus,
  isSoloPreview = false,
  location,
}) => {
  const {
    createItem,
    selectedAreaType,
    setSelectedAreaType,
    selectedBrushSize,
    setSelectedBrushSize,
    selectedCompareImage,
    setSelectedCompareImage,
    selectedTool,
    studioTask,
  } = useStudioContext();

  const [isSelectionInProgress, setIsSelectionInProgress] = useState(false);

  const isPreviewComparison = taskStatus === 'COMPLETED' && isSoloPreview;

  const editTools = STUDIO_TOOLS_CONFIG[selectedTool]?.canUseEditTools;

  useEffect(() => {
    if (editTools?.includes('area')) {
      setIsSelectionInProgress(true);
    } else if (selectedTool !== '') {
      setIsSelectionInProgress(true);
    } else {
      setIsSelectionInProgress(false);
    }
  }, [selectedTool]);

  return (
    <div className="relative flex h-12 items-start justify-between">
      <h2 className="pt-2 text-2xl font-bold leading-6 text-primary">Result</h2>
      <div
        className="absolute left-1/2 flex -translate-x-1/2 items-center gap-4 py-1"
        id="tools"
      >
        {editTools?.includes('area') &&
          createItem?.canUseEditTools?.includes('area') && (
            <SettingsButtonGroup
              variant="area"
              selectedValue={selectedAreaType}
              onValueChange={setSelectedAreaType}
            />
          )}
        {editTools?.includes('brush') &&
          createItem?.canUseEditTools?.includes('brush') && (
            <SettingsButtonGroup
              variant="brush"
              selectedValue={selectedBrushSize}
              onValueChange={setSelectedBrushSize}
            />
          )}
        {isPreviewComparison &&
          selectedTool === '' &&
          (createItem?.hasCompareResult ||
            STUDIO_TOOLS_CONFIG[studioTask?.generateTaskType]
              ?.hasCompareResult) &&
          studioTask?.generateTaskType !== 'UPSCALE_IMAGE' &&
          !location.search.includes('generationType') && (
            <SettingsButtonGroup
              variant="view"
              selectedValue={selectedCompareImage}
              onValueChange={setSelectedCompareImage}
            />
          )}
      </div>
      <div className="flex flex-row gap-x-2">
        {!isSelectionInProgress ? (
          <div className="flex items-center gap-x-3">
            <SaveToCollection
              id={selectedImage?.studioItemId}
              type="studioItem"
              variant="studio"
            />
            <SidebarButton
              text="Download"
              variant="tool"
              className="py-2 px-4 font-bold"
              onClick={() =>
                downloadFile(selectedImage?.url, 'ai-generated-image')
              }
            />
          </div>
        ) : (
          <div className="flex items-center gap-x-3">
            <GenerateButton variant="apply" />
          </div>
        )}
        {onClose && (
          <div className="flex cursor-pointer items-center gap-x-4 hover:text-primary">
            <X size={36} onClick={onClose} strokeWidth={1.5} />
          </div>
        )}
      </div>
    </div>
  );
};

export const ImageDisplay = ({
  generatedImages,
  orientation,
  downloadFile,
}) => {
  const {
    displayMode,
    selectedGeneratedImage,
    handleImageClick,
    closePreview,
    studioTask,
  } = useImageDisplay(generatedImages, orientation);
  return (
    <div className="ml-auto flex h-full max-w-[50rem] flex-col gap-4 rounded-lg border border-secondary/50 bg-primaryLight p-6 sm:mx-auto">
      <ImageHeader
        displayMode={displayMode}
        taskStatus={studioTask?.status}
        generationTaskType={studioTask?.generateTaskType}
        generatedImages={generatedImages}
        selectedGeneratedImage={selectedGeneratedImage}
        closePreview={closePreview}
        downloadFile={downloadFile}
        studioTask={studioTask}
      />
      <ImageContent
        displayMode={displayMode}
        studioTask={studioTask}
        generatedImages={generatedImages}
        orientation={orientation}
        selectedGeneratedImage={selectedGeneratedImage}
        handleImageClick={handleImageClick}
      />
    </div>
  );
};
